
import React from 'react';

import { connect } from 'react-redux';
import LoftyEditorJsReadOnly from '../../components/LoftyEditorJsReadOnly';
import LoftyEditorJs from '../../components/LoftyEditorJs';



import './errorpayment.scss';


// Hamburger
function loftyInitHam() {
  try {
    // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}





const Perrorpayment = ({ match, loftytoken, loftyuser, addLoftyUserApp, deleteLoftytokenApp, addLoftyTokenApp }) => {



  setTimeout(() => { loftyInitHam(); }, 2000);





  return (
    <div id="id65119ad3925546452564dbd6" >

      <div id="ivee" className="">

        <a
          target=""
          className=""
          id="iwlywh"
          href="/"
        >

          <img
            className=""
            id="i1i8k"
            src="https://assetsprojects.s3.amazonaws.com/eewk3il9okodib.png"
            alt="undefined"
          />

        </a>

        <div id="" className="contentwrapperanimated ">

          <div id="" className="container_animated ">

            <label id="" className="menu__btn ">

              <span className="span_before " id="">

              </span>

              <span className="span_middle " id="">

              </span>

              <span className="span_after " id="">

              </span>

            </label>

            <label id="" className="menu__close__btn ">

              <span className="span__close__left " id="">

              </span>

              <span className="span__close__right " id="">

              </span>

            </label>

            <ul id="" className="menu__box ">

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/"
                  type=""
                >
                  <span>Home</span>
                </a>

              </li>

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/products"
                  type=""
                >
                  <span>Shop</span>
                </a>

              </li>

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/aboutus"
                  type=""
                >
                  <span>About Us</span>
                </a>

              </li>

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/cartpage"
                  type=""
                >
                  <span>Shopping Cart</span>
                </a>

              </li>

              <li className="linkshref " id="">

              </li>

            </ul>

          </div>

        </div>

        <div id="iq9l" className="">

          <a
            target=""
            className=""
            id="i5tql"
            href="/"
          >

            <div id="i1n85" className="">
              <span>Home</span>
            </div>

          </a>

          <a
            target=""
            className=""
            id="ifv1s"
            href="/products"
          >

            <div id="iqceg" className="">
              <span>Shop</span>
            </div>

          </a>

          <a
            target=""
            className=""
            id="ikkdy"
            href="/aboutus"
          >

            <div id="if57z" className="">
              <span>About Us</span>
            </div>

          </a>

          <a
            target=""
            className=""
            id="i0hj6k"
            href="/blog"
          >

            <div id="ivd9wj" className="">
              <span>Blog</span>
            </div>

          </a>

          <div id="islmj" className="">

            <div id="irz9ej" className="">

              <input
                name=""
                type="text"
                placeholder="Search"
                className=""
                id="i42p08"
              />

            </div>

            <button
              type=""
              name=""
              id="irfkh"
              className=""

            >

              <div id="ivb27" className="">
                <span>Search</span>
              </div>

            </button>

          </div>

          <div id="inqxd" className="">

            <a
              target=""
              className=""
              id="i6hs3"
              href="/cartpage"
            >

              <img
                className=""
                id="ijy2n"
                src="https://assetsprojects.s3.amazonaws.com/404p7nilj3fs1w7.png"
                alt="undefined"
              />

            </a>

          </div>

        </div>

      </div>

      <div id="ihis35" className="">

        <div id="ipdx1n" className="">

          <div id="iud3s7" className="">

            <p className="" id="icco8t">
              <span>Error Payment</span>
            </p>

            <div id="ioi4zh" className="">

              <p className="" id="i0iv3a">
                <span>Dear Customer</span>
                <br className="" id="" />

                <br className="" id="" />
                <span>I hope you are well. We want to thank you for your recent purchase in our online store and express our gratitude for your preference.</span>
                <br className="" id="" />

                <br className="" id="" />
                <span>However, unfortunately we have encountered a problem with the processing of your payment. We have detected an error in the transaction that has prevented the purchase from being completed successfully. We understand how frustrating this can be, and we are working diligently to resolve this situation as quickly as possible.</span>
                <br className="" id="" />

                <br className="" id="" />
                <span>To resolve this issue, we suggest you perform the following steps:</span>
                <br className="" id="" />

                <br className="" id="" />
                <span>Check payment information: Make sure your credit card or bank account details are correct and up to date. Often a typographical error or outdated information can cause processing problems.</span>
                <br className="" id="" />

                <br className="" id="" />
                <span>Sufficient Funds: Confirm that you have sufficient funds in your account or available credit limit to cover the purchase you are attempting to make.</span>
                <br className="" id="" />

                <br className="" id="" />
                <span>Alternative Payment Method: If you are still experiencing issues, consider using another valid payment method, such as a different credit card or alternative bank account.</span>
                <br className="" id="" />

                <br className="" id="" />
                <span>If the problem persists after performing these steps, we recommend contacting our customer service. Our team will be happy to help you identify and resolve the payment issue as efficiently as possible.</span>
                <br className="" id="" />

                <br className="" id="" />
                <span>We apologize for any inconvenience this may have caused and appreciate your patience while we work on the solution. If you have any questions or need additional assistance, please do not hesitate to contact our customer service.</span>
              </p>

            </div>

            <div id="i32ox2" className="">

              <a
                target=""
                className=""
                id="i0ojhc"
                href="#"
              >

                <div id="iei5d8" className="">
                  <span>Try again</span>
                </div>

              </a>

            </div>

          </div>

          <div id="ib241h" className="">

            <p className="" id="iq990n">
              <span>PAYMENT INFORMATION</span>
            </p>

            <div id="" className="formContainer ">

              <form
                id=""
                className=""

              >

                <div id="" className="title ">

                  <i className="fas fa-pencil-alt " id="">

                  </i>

                </div>

                <div id="" className="fieldsContainer ">

                  <div id="iqweg8" className="">

                    <div id="ivd9n9" className="">

                      <div id="i9vgjp" className="">
                        <span>Name</span>
                      </div>

                      <input
                        name=""
                        type="text"
                        placeholder=""
                        className="componentInput "
                        id=""
                      />

                    </div>

                    <div id="iq0ga3" className="">

                      <div id="iq3ifc" className="">
                        <span>Type Card</span>
                      </div>

                      <input
                        name=""
                        type="text"
                        placeholder=""
                        className="componentInput "
                        id=""
                      />

                    </div>

                    <div id="iix18y" className="">

                      <div id="iiu0oq" className="">
                        <span>Card Number</span>
                      </div>

                      <input
                        name=""
                        type="text"
                        placeholder=""
                        className="componentInput "
                        id=""
                      />

                    </div>

                  </div>

                  <div id="ils4ts" className="">

                    <div id="iuyegd" className="">

                      <div id="igohcl" className="">
                        <span>Expiration (MMYY)</span>
                      </div>

                      <input
                        name=""
                        type="text"
                        placeholder=""
                        className="componentInput "
                        id=""
                      />

                    </div>

                    <div id="i6jeuz" className="">

                      <div id="ipq6dx" className="">
                        <span>CVV</span>
                      </div>

                      <input
                        name=""
                        type="text"
                        placeholder=""
                        className="componentInput "
                        id=""
                      />

                    </div>

                  </div>

                  <div id="ig8syl" className="">

                    <button
                      type=""
                      name=""
                      id="i887bi"
                      className="standard_button "

                    >

                      <div id="i6irzp" className="">
                        <span>Realizar Pago</span>
                      </div>

                    </button>

                  </div>

                </div>

              </form>

            </div>

          </div>

        </div>

      </div>

      <div id="ie6sa" className="">

        <div id="iqobg" className="">

          <p className="" id="ibllc">
            <span>Contact us for more information!</span>
            <br className="" id="" />

          </p>

          <div id="ihuk4" className="">

            <div id="iu87j" className="">

              <img
                className=""
                id="i67f"
                src="https://assetsprojects.s3.amazonaws.com/eewk3il9okodib.png"
                alt="undefined"
              />

            </div>

            <div id="iwg4f" className="">

              <a
                target="_blank"
                className=""
                id="irgjju"
                href="https://www.instagram.com/spottytaghn/"
              >

                <img
                  className=""
                  id="iqcul1"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hafi3.png"
                  alt="undefined"
                />

                <p className="" id="iqq4mu">
                  <span>@spottytaghn</span>
                </p>

              </a>

              <a
                target="_blank"
                className=""
                id="i5vgyt"
                href="https://www.facebook.com/spottytaghn"
              >

                <img
                  className=""
                  id="iae27s"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3he6f6.png"
                  alt="undefined"
                />

                <p className="" id="ikdl4l">
                  <span>spottytaghn</span>
                </p>

              </a>

              <a
                target=""
                className=""
                id="ii2lsw"
                href="https://api.whatsapp.com/send?phone=50433027674"
              >

                <img
                  className=""
                  id="iu0rp5"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hfpjb.png"
                  alt="undefined"
                />

                <p className="" id="iqzql2">
                  <span>+504 3302-7674</span>
                </p>

              </a>

              <a
                target=""
                className=""
                id="i0mmmx"
                href="mailto:customerservice@spottytag.com"
              >

                <img
                  className=""
                  id="ih8ro2"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hg2uk.png"
                  alt="undefined"
                />

                <p className="" id="ivdrgi">
                  <span>customerservice@spottytag.com</span>
                </p>

              </a>

            </div>

          </div>

        </div>

      </div>

      <div id="ig891" className="">

        <p className="" id="i7l1k">
          <span>©2023 | spotty tag hn | privacy policies | terms of use</span>
          <br className="" id="" />

        </p>

      </div>

    </div>
  );
}

const mapStateToProps = (state) => state.auth;



export default connect(mapStateToProps)(Perrorpayment);
