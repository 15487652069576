
import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import AOS from 'aos';
import {connect} from 'react-redux';
import {addLoftyStore, deleteLoftyStore} from './actions/store.actions';
import { getAllstore } from './api/store.api';

// pages
import pagenewhome from './pages/pagenewhome/pagenewhome'
import cartpage from './pages/cartpage/cartpage'
import checkoutpage from './pages/checkoutpage/checkoutpage'
import products from './pages/products/products'
import itembyid from './pages/itembyid/itembyid'
import reportform from './pages/reportform/reportform'
import aboutus from './pages/aboutus/aboutus'
import terms2 from './pages/terms2/terms2'
import policies2 from './pages/policies2/policies2'
import ordersummary from './pages/ordersummary/ordersummary'
import resetpassword from './pages/resetpassword/resetpassword'
import aboutussocial from './pages/aboutussocial/aboutussocial'
import errorpayment from './pages/errorpayment/errorpayment'
import blog from './pages/blog/blog'
import articulo from './pages/articulo/articulo'
import error404 from './pages/error404/error404'
import homeqr from './pages/homeqr/homeqr';

// css
import './App.css';
import 'aos/dist/aos.css';

AOS.init();

function initSwiper() {
  // eslint-disable-next-line no-undef
  const swiper = new Swiper('.mySwiper', {
    spaceBetween: 30,
    centeredSlides: true,
    autoplay: false,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
  // console.log('swiper :>> ', swiper);
}

const App = (props) => {

  // agregando iniciación para el Swipper
  useEffect(() => {
    setTimeout(() => {
      initSwiper();
    }, 2000);
  }, []);

  const [loading, setLoading] = useState(true);
  const {limitDate, addLoftyStoreApp, deleteLoftyStoreApp} = props;
  useEffect(() => {
    const handleData = async () => {
      if (limitDate) {
        const now = new Date();
        console.log(limitDate.getTime() < now.getTime())
        if (limitDate.getTime() < now.getTime()) {
          deleteLoftyStoreApp();
        }
      } else {
        const listStoreSpottyTag = await getAllstore();
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const idStore = urlParams.get('store');
        const store = listStoreSpottyTag.data?.find(({identificator}) => identificator === idStore);
        if (store) {
          console.log(store);
          addLoftyStoreApp({store});
        }
      }
      setLoading(false);
    }
    handleData();
  }, []);
  if (loading) {
    return <div>Loading ...</div>
  }


  return (
    <Router>
      <Switch>
			<Route exact path="/" component={pagenewhome} />
      <Route exact path="/home" component={homeqr} />
			<Route exact path="/cartpage" component={cartpage} />
			<Route exact path="/checkout" component={checkoutpage} />
			<Route exact path="/products" component={products} />
			<Route exact path="/item/:id" component={itembyid} />
			<Route exact path="/report/item/:id" component={reportform} />
			<Route exact path="/aboutus" component={aboutus} />
			<Route exact path="/terms" component={terms2} />
			<Route exact path="/policies" component={policies2} />
			<Route exact path="/order/:id" component={ordersummary} />
			<Route exact path="/reset-password/:id" component={resetpassword} />
			<Route exact path="/links" component={aboutussocial} />
			<Route exact path="/errorpayment" component={errorpayment} />
			<Route exact path="/blog" component={blog} />
			<Route exact path="/article/:id" component={articulo} />
			<Route exact path="*" component={error404} />

      </Switch>
    </Router>
  );
}

const mapStateToProps = (state) => ({...state.shoppingcart, ...state.store});

const mapDispatchToProps = (dispatch) => ({
  addLoftyStoreApp: (params) => dispatch(addLoftyStore(params)),
  deleteLoftyStoreApp: () => dispatch(deleteLoftyStore()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

