
import axios from '../config/axios';

export const url = '/api/country'


export const getAllcountry = ({
  token,
}) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}//list/basic/621c06fe6bef5b00160bf4bd`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});
      
export const getOnecountry = ({
  token,
  _id,
}) => new Promise((resolve, reject) => {
  if (token && _id) {
    axios.get(`${url}//one/basic/6231ecafdb4b270016908d8b/${_id}`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!_id) {
    reject({
      status: 'error',
      info: 'Identificador no definido'
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});
      

