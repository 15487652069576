
import React from 'react';

import { connect } from 'react-redux';
import LoftyEditorJsReadOnly from '../../components/LoftyEditorJsReadOnly';
import LoftyEditorJs from '../../components/LoftyEditorJs';



import './aboutus.scss';


// Hamburger
function loftyInitHam() {
  try {
    // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}





const Paboutus = ({ match, loftytoken, loftyuser, addLoftyUserApp, deleteLoftytokenApp, addLoftyTokenApp }) => {



  setTimeout(() => { loftyInitHam(); }, 2000);





  return (
    <div id="id64d9f7d59255464525647295" >

      <div id="ivee" className="">

        <a
          target=""
          className=""
          id="isj626"
          href="/"
        >

          <img
            className=""
            id="i1i8k"
            src="https://assetsprojects.s3.amazonaws.com/eewk3il9okodib.png"
            alt="undefined"
          />

        </a>

        <div id="" className="contentwrapperanimated ">

          <div id="" className="container_animated ">

            <label id="" className="menu__btn ">

              <span className="span_before " id="">

              </span>

              <span className="span_middle " id="">

              </span>

              <span className="span_after " id="">

              </span>

            </label>

            <label id="" className="menu__close__btn ">

              <span className="span__close__left " id="">

              </span>

              <span className="span__close__right " id="">

              </span>

            </label>

            <ul id="" className="menu__box ">

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/"
                  type=""
                >
                  <span>Home</span>
                </a>

              </li>

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/products"
                  type=""
                >
                  <span>Shop</span>
                </a>

              </li>

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/aboutus"
                  type=""
                >
                  <span>About Us</span>
                </a>

              </li>

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/cartpage"
                  type=""
                >
                  <span>Shopping Cart</span>
                </a>

              </li>

              <li className="linkshref " id="">

              </li>

            </ul>

          </div>

        </div>

        <div id="iq9l" className="">

          <a
            target=""
            className=""
            id="i5tql"
            href="/"
          >

            <div id="i1n85" className="">
              <span>Home</span>
            </div>

          </a>

          <a
            target=""
            className=""
            id="ifv1s"
            href="/products"
          >

            <div id="iqceg" className="">
              <span>Shop</span>
            </div>

          </a>

          <a
            target=""
            className=""
            id="ikkdy"
            href="/aboutus"
          >

            <div id="if57z" className="">
              <span>About Us</span>
            </div>

          </a>

          <a
            target=""
            className=""
            id="izgs38"
            href="/blog"
          >

            <div id="i2rrxp" className="">
              <span>Blog</span>
            </div>

          </a>

          <form id="islmj" className="" onSubmit={(e) => {
              e.preventDefault();
              const doc = {
                q: e.target.search.value,
              }
              const query = new URLSearchParams(doc);
              window.location.href = `/products?${query.toString()}`
            }}>

            <div id="iwr3jd" className="">

              <input
                name=""
                type="text"
                placeholder="Search"
                className=""
                id="ib4ir6"
              />

            </div>

            <button
              type=""
              name=""
              id="iyknx"
              className=""

            >

              <div id="ivb27" className="">
                <span>Search</span>
              </div>

            </button>

          </form>

          <div id="inqxd" className="">

            <a
              target=""
              className=""
              id="i6hs3"
              href="/cartpage"
            >

              <img
                className=""
                id="ijy2n"
                src="https://assetsprojects.s3.amazonaws.com/404p7nilj3fs1w7.png"
                alt="undefined"
              />

            </a>

          </div>

        </div>

      </div>

      <div id="ipezzh" className="">

        <div id="ivp3c" className="">

          <p className="" id="i0pfy">
            <span>About Us</span>
          </p>

        </div>

      </div>

      <div id="ihis35" className="">

        <div id="ixobj" className="">

          <div id="ighwp" className="">

            <div id="iax44" className="">
              <iframe
                title="Video Youtube"
                src="https://www.youtube.com/embed/AyTW0OavMJE?"
                allowFullScreen="undefined"
                style={{
                  width: '100%',
                  height: '100%',
                }}
              />
            </div>

          </div>

          <div id="iml6i" className="">

            <p className="" id="i6rsk">

              <p className="MsoNormal " id="ia7d7">
                <span>OUR PET PRODUCTS:</span>
                <br className="" id="" />

                <br className="" id="" />
                <span>ID TAG</span>
                <br className="" id="" />

                <br className="" id="" />
                <span>HEALT & GROOMING FILES TAG * may be purchased separately</span>
                <br className="" id="" />

                <br className="" id="" />
                <span>SPECIAL OFFER:</span>
                <br className="" id="" />
                <span>It's time to spoil, protect and care for your pets, by  taking advantage of this fabulous promotion we are offering!!</span>
                <br className="" id="" />
                <span>Because you love them and recognize them as part of your family, you care for their health, hygien and beauty, and for such we offer you technological support and backup with our  SPOTTY TAGS, either the ID TAG, or the HEALT & GROOMING FILES TAG, that helps you keep your medical and grooming files.</span>
                <br className="" id="" />
                <span>If your Pet does not yet have The Identification Tag,  our Promotion includes a free IDENTIFICATION KIT, which allows you to maintain the digital Health and grooming Files, and electronic scheduling of up coming appointments for care, protection and grooming  as well as a control of the medical history. What are you waiting for to have all this installed on their collar?</span>
                <br className="" id="" />
                <span> That way you will always be in control of your beloved Furry  pet's care, protection and well being.</span>
                <br className="" id="" />

                <br className="" id="" />
                <span>What's included in this special deal offered by Spotty Tag?</span>
                <br className="" id="" />
                <span>When you buy a Metalic Pet Identification tag in the shape of a bone, in the color you select, you receive a free PET IDENTIFICATION KIT, which contains the following:</span>
                <br className="" id="" />

                <br className="" id="" />
                <span>Health & Grooming Files Tag, that helps you keep your medical and grooming files, which comes with a NFC Microchip and QR Code for digital ID# back up, which, when scanned with your cellphone through the Mobil App you access the profile which contains images and all vital information on your Pet, which you have entered on the database, both on its medical or grooming files, as well as direct contact to Whatsapp, email and land phone so that you can issue reports and notifications, as well as receive reports, details and last location, in case your Furry Friend gets lost. The NFC microchip works as a backup device to save all the information related to your pet.</span>
                <br className="" id="" />

                <br className="" id="" />
                <span>Use of the SPOTTY TAG Mobil App create and control your Pet's Profile, maintain an Electronic Agenda for Appointments and a Health & Grooming Files TAG, which it allows you to issue alerts and receive reports in case the pet gets lost, and to send to the Vet and/or Groomer the Medical file or the Grooming done prior to your next appointment, in PDF form to determin what follows.</span>
                <br className="" id="" />

                <br className="" id="" />
                <span>Digital Familiarity Certificate, with which you carry out the action of formally recognizing your beloved Pet as part of your family. </span>
                <br className="" id="" />

                <br className="" id="" />
                <span> Free Shipping</span>
                <br className="" id="" />

              </p>

            </p>

          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              paddingRight: '2%',
              paddingLeft: '3%',
              flexWrap: 'wrap',
            }}
          >
            <div style={{margin: 10}} id="iax44" className="">
              <iframe
                title="Video Youtube"
                src="https://www.youtube.com/embed/Xxp-sHI4fcE?"
                allowFullScreen="undefined"
                style={{
                  width: '100%',
                  height: '100%',
                }}
              />
            </div>
            <img
              id='iax44'
              style={{
                margin: 10
              }}
              src='https://assetsprojects.s3.amazonaws.com/eewk5ilyp5otak.jpeg'
              alt='imagem'
            />
          </div>

        </div>

      </div>

      <div id="ie6sa" className="">

        <div id="iqobg" className="">

          <p className="" id="ibllc">
            <span>Contact us for more information!</span>
            <br className="" id="" />

          </p>

          <div id="ihuk4" className="">

            <div id="iu87j" className="">

              <img
                className=""
                id="i67f"
                src="https://assetsprojects.s3.amazonaws.com/eewk3il9okodib.png"
                alt="undefined"
              />

            </div>

            <div id="iwg4f" className="">

              <a
                target="_blank"
                className=""
                id="irgjju"
                href="https://www.instagram.com/spottytaghn/"
              >

                <img
                  className=""
                  id="iqcul1"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hafi3.png"
                  alt="undefined"
                />

                <p className="" id="iqq4mu">
                  <span>@spottytaghn</span>
                </p>

              </a>

              <a
                target="_blank"
                className=""
                id="i5vgyt"
                href="https://www.facebook.com/spottytaghn"
              >

                <img
                  className=""
                  id="iae27s"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3he6f6.png"
                  alt="undefined"
                />

                <p className="" id="ikdl4l">
                  <span>spottytaghn</span>
                </p>

              </a>

              <a
                target=""
                className=""
                id="ii2lsw"
                href="https://api.whatsapp.com/send?phone=50433027674"
              >

                <img
                  className=""
                  id="iu0rp5"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hfpjb.png"
                  alt="undefined"
                />

                <p className="" id="iqzql2">
                  <span>+504 3302-7674</span>
                </p>

              </a>

              <a
                target=""
                className=""
                id="i0mmmx"
                href="mailto:customerservice@spottytag.com"
              >

                <img
                  className=""
                  id="ih8ro2"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hg2uk.png"
                  alt="undefined"
                />

                <p className="" id="ivdrgi">
                  <span>customerservice@spottytag.com</span>
                </p>

              </a>

            </div>

          </div>

        </div>

      </div>

      <div id="ig891" className="">

        <p className="" id="i7l1k">
          <span>©2023 | spotty tag hn | privacy policies | terms of use</span>
          <br className="" id="" />

        </p>

      </div>

    </div>
  );
}

const mapStateToProps = (state) => state.auth;



export default connect(mapStateToProps)(Paboutus);
