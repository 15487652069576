
import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { getAllproducts } from '../../api/products.api';



import './homeqr.scss';
import { addLoftyShoppingCart } from '../../actions/shoppingcart.actions';


// Hamburger
function loftyInitHam() {
  try {
    // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}





const Ppagenewhome = ({ store, loftytoken, addLoftyShoppingCartApp, loftyShoppingCart }) => {

  const [products, setProducts] = useState([]);
  const [swiperProduct, setSwiperProduct] = useState();

  useEffect(() => {
    const handleData = async () => {
      try {
        const res = await getAllproducts({
          token: 'asdsadsa'
        });
        const newProducts = [];
        res.data?.forEach((product) => {
          if (product.status === 'public' && product.needinfo === 'yes') {
            newProducts.push(product);
          }
        });
        res.data?.forEach((product) => {
          if (product.status === 'public' && product.needinfo === 'no') {
            newProducts.push(product);
          }
        });
        setProducts(newProducts);
      } catch (error) {
        console.log(error);
      }
    }
    handleData();
    setTimeout(() => {
      loftyInitHam();
      // eslint-disable-next-line no-undef
      const swiper = new Swiper(".mySwiperproduct", {
        slidesPerView: "auto",
        grabCursor: true,
        spaceBetween: 30,
        freeMode: true,
      });
      setSwiperProduct(swiper);
    }, 2000);
  }, []);

  return (
    <div id="id6490d4afa52ebcacc7a80394" >

      <div id="ivee" className="">

        <a
          target=""
          className=""
          id="i1dgzw"
          href="/"
        >

          <img
            className=""
            id="i1i8k"
            src="https://assetsprojects.s3.amazonaws.com/eewk3il9okodib.png"
            alt="undefined"
          />

        </a>

        <a
          target=""
          className="shopping-container-mobile"
          id="i6hs3"
          href="/cartpage"
        >

          <img
            className=""
            id="ijy2n"
            src="https://assetsprojects.s3.amazonaws.com/406racilqpyblxz.png"
            alt="undefined"
          />

        </a>

        <div id="" className="contentwrapperanimated ">

          <div id="" className="container_animated ">

            <label id="" className="menu__btn ">

              <span className="span_before " id="">

              </span>

              <span className="span_middle " id="">

              </span>

              <span className="span_after " id="">

              </span>

            </label>

            <label id="" className="menu__close__btn ">

              <span className="span__close__left " id="">

              </span>

              <span className="span__close__right " id="">

              </span>

            </label>

            <ul id="" className="menu__box ">

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/"
                  type=""
                >
                  <span>Home</span>
                </a>

              </li>

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/products"
                  type=""
                >
                  <span>Shop</span>
                </a>

              </li>

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/aboutus"
                  type=""
                >
                  <span>About Us</span>
                </a>

              </li>

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/cartpage"
                  type=""
                >
                  <span>Shopping cart</span>
                </a>

              </li>

              <li className="linkshref " id="">

              </li>

            </ul>

          </div>

        </div>

        <div id="iq9l" className="">

          <a
            target=""
            className=""
            id="i5tql"
            href="/"
          >

            <div id="i1n85" className="">
              <span>Home</span>
            </div>

          </a>

          <a
            target=""
            className=""
            id="ifv1s"
            href="/products"
          >

            <div id="iqceg" className="">
              <span>Shop</span>
            </div>

          </a>

          <a
            target=""
            className=""
            id="ikkdy"
            href="/aboutus"
          >

            <div id="if57z" className="">
              <span>About Us</span>
            </div>

          </a>

          <form
            id="islmj"
            className=""
            onSubmit={(e) => {
              e.preventDefault();
              const doc = {
                q: e.target.search.value,
              }
              const query = new URLSearchParams(doc);
              window.location.href = `/products?${query.toString()}`
            }}
          >

            <div id="i8ln1r" className="">

              <input
                name="search"
                className=""
                id="iorknq"
              />

            </div>

            <button
              type="submit"
              name=""
              id="ihcii"
              className=""

            >

              <div id="ivb27" className="">
                <span>Search</span>
              </div>

            </button>

          </form>

          <div id="inqxd" className="">

            <a
              target=""
              className=""
              id="i6hs3"
              href="/cartpage"
            >

              <img
                className=""
                id="ijy2n"
                src="https://assetsprojects.s3.amazonaws.com/406racilqpyblxz.png"
                alt="undefined"
              />

            </a>

          </div>

        </div>

      </div>

      <div id="i3wp2n" className="">

        <div id="iq6mrf" className="">

          <p className="" id="iav37s">
            <span>Mobile App Spotty Tag</span>
          </p>

          <p className="" id="i66i0t">
            <span>*To create and control your profile and keep the Electronic Diary and Health & Grooming Files TAG.</span>
            <br className="" id="" />
            <span>*It allows issuing alerts and receiving reports in case of loss of the pet</span>
            <br className="" id="" />
            <span>*Send to the Veterinarian or Grooming in PDF the medical or hygiene and beauty history</span>
            <br className="" id="" />
            <span>prior to your next appointment to know the latest diagnosis, prescription or treatment performed</span>
            <br className="" id="" />
            <span>to predetermine what comes next.</span>
          </p>

          <div id="imx1a4" className="">

            <a
              target=""
              className=""
              id="i84a68"
              href="https://apps.apple.com/hn/app/spotty-tag/id6443884976"
            >

              <img
                className=""
                id="ixu5m4"
                src="https://assetsprojects.s3.amazonaws.com/404p7nilj3if214.png"
                alt="undefined"
              />

            </a>

            <a
              target=""
              className=""
              id="i0r6tk"
              href="https://play.google.com/store/apps/details?id=com.fishermindtech.spottytag"
            >

              <img
                className=""
                id="i35c9u"
                src="https://assetsprojects.s3.amazonaws.com/404p7nilj3if7n3.png"
                alt="undefined"
              />

            </a>

          </div>

        </div>

        <img
          className=""
          id="ig4vw5"
          src="https://assetsprojects.s3.amazonaws.com/406racilqcn4u8r.png"
          alt="undefined"
        />

      </div>

      <div id="ie6sa" className="">

        <div id="iqobg" className="">

          <p className="" id="ibllc">
            <span>Contact us for more information!</span>
            <br className="" id="" />

          </p>

          <div id="ihuk4" className="">

            <div id="iu87j" className="">

              <img
                className=""
                id="i67f"
                src="https://assetsprojects.s3.amazonaws.com/eewk3il9okodib.png"
                alt="undefined"
              />

            </div>

            <div id="iwg4f" className="">

              <a
                target="_blank"
                className=""
                id="irgjju"
                href="https://www.instagram.com/spottytaghn/"
              >

                <img
                  className=""
                  id="iqcul1"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hafi3.png"
                  alt="undefined"
                />

                <p className="" id="iqq4mu">
                  <span>@spottytaghn</span>
                </p>

              </a>

              <a
                target="_blank"
                className=""
                id="i5vgyt"
                href="https://www.facebook.com/spottytaghn"
              >

                <img
                  className=""
                  id="iae27s"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3he6f6.png"
                  alt="undefined"
                />

                <p className="" id="ikdl4l">
                  <span>spottytaghn</span>
                </p>

              </a>

              <a
                target=""
                className=""
                id="ii2lsw"
                href="https://api.whatsapp.com/send?phone=50433027674"
              >

                <img
                  className=""
                  id="iu0rp5"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hfpjb.png"
                  alt="undefined"
                />

                <p className="" id="iqzql2">
                  <span>+504 3302-7674</span>
                </p>

              </a>

              <a
                target=""
                className=""
                id="i0mmmx"
                href="mailto:customerservice@spottytag.com"
              >

                <img
                  className=""
                  id="ih8ro2"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hg2uk.png"
                  alt="undefined"
                />

                <p className="" id="ivdrgi">
                  <span>customerservice@spottytag.com</span>
                </p>

              </a>

            </div>

          </div>

        </div>

      </div>

      <div id="ig891" className="">

        <p className="" id="i7l1k">
          <span>©2023 | spotty tag hn | privacy policies | terms of use</span>
          <br className="" id="" />

        </p>

      </div>

    </div>
  );
}

const mapStateToProps = (state) => ({ ...state.shoppingcart, ...state.store });

const mapDispatchToProps = (dispatch) => ({
  addLoftyShoppingCartApp: (loftyShoppingCart) => dispatch(addLoftyShoppingCart({ loftyShoppingCart }))
});

export default connect(mapStateToProps, mapDispatchToProps)(Ppagenewhome);
