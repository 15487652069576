
import axios from '../config/axios';

export const url = '/api/notifications'

export const createnotifications = ({
  token,
	iditem,
	titlespanish,
	titleenglish,
	descriptionspanish,
	descriptionenglish,
	type,
	userid,
	reportid,
	idveterinaryappointment,
	idgroomingappointment,

}) => new Promise((resolve, reject) => {
  if (token && iditem && titlespanish && titleenglish && descriptionspanish && descriptionenglish && type && userid ) {

    axios.post(`${url}/create/basic/638532c00e1b40958ca56c2b`, {
		iditem,
		titlespanish,
		titleenglish,
		descriptionspanish,
		descriptionenglish,
		type,
		userid,
		reportid,
		idveterinaryappointment,
		idgroomingappointment,

    }, {
      headers: {
        
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch(error => reject(error));
  } else if (!iditem) {
		reject({
			status: 'error',
			info: 'iditem no esta definido',
		});
	}  else if (!titlespanish) {
		reject({
			status: 'error',
			info: 'titlespanish no esta definido',
		});
	}  else if (!titleenglish) {
		reject({
			status: 'error',
			info: 'titleenglish no esta definido',
		});
	}  else if (!descriptionspanish) {
		reject({
			status: 'error',
			info: 'descriptionspanish no esta definido',
		});
	}  else if (!descriptionenglish) {
		reject({
			status: 'error',
			info: 'descriptionenglish no esta definido',
		});
	}  else if (!type) {
		reject({
			status: 'error',
			info: 'type no esta definido',
		});
	}  else if (!userid) {
		reject({
			status: 'error',
			info: 'userid no esta definido',
		});
	}  else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});

export const updatenotifications = ({
  token,
  _id,
	iditem,
	titlespanish,
	titleenglish,
	descriptionspanish,
	descriptionenglish,
	type,
	userid,
	reportid,
	idveterinaryappointment,
	idgroomingappointment,

}) => new Promise((resolve, reject) => {
  if (token && _id && iditem && titlespanish && titleenglish && descriptionspanish && descriptionenglish && type && userid ) {
    axios.post(`${url}/update/${_id}`, {
		iditem,
		titlespanish,
		titleenglish,
		descriptionspanish,
		descriptionenglish,
		type,
		userid,
		reportid,
		idveterinaryappointment,
		idgroomingappointment,

    }, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error))
  } else if (!iditem) {
		reject({
			status: 'error',
			info: 'iditem no esta definido',
		});
	}  else if (!titlespanish) {
		reject({
			status: 'error',
			info: 'titlespanish no esta definido',
		});
	}  else if (!titleenglish) {
		reject({
			status: 'error',
			info: 'titleenglish no esta definido',
		});
	}  else if (!descriptionspanish) {
		reject({
			status: 'error',
			info: 'descriptionspanish no esta definido',
		});
	}  else if (!descriptionenglish) {
		reject({
			status: 'error',
			info: 'descriptionenglish no esta definido',
		});
	}  else if (!type) {
		reject({
			status: 'error',
			info: 'type no esta definido',
		});
	}  else if (!userid) {
		reject({
			status: 'error',
			info: 'userid no esta definido',
		});
	}  else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});

export const getAllnotifications = ({
  token,
}) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}/list`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});

export const getOnenotifications = ({
  token,
  _id,
}) => new Promise((resolve, reject) => {
  if (token && _id) {
    axios.get(`${url}/one/${_id}`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!_id) {
    reject({
      status: 'error',
      info: 'Identificador no definido'
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});

export const deletenotifications = ({
  token,
  _id,
}) => new Promise((resolve, reject) => {
  if (token && _id) {
    axios.delete(`${url}/delete/${_id}`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!_id) {
    reject({
      status: 'error',
      info: 'Identificador no definido',
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
})

