
import axios from '../config/axios';

export const url = '/api/products'

export const createproducts = ({
  token,
	name,
	description,
	normalprice,
	disccountprice,
	status,
	sku,
  loftyFile,
}) => new Promise((resolve, reject) => {
  if (token && name && description && normalprice && disccountprice && sku ) {
		const formData = new FormData();
		formData.append('loftyFile', loftyFile);
		formData.append('name', name);
    formData.append('description', description);
    formData.append('normalprice', normalprice);
    formData.append('disccountprice', disccountprice);
    formData.append('status', status);
    formData.append('sku', sku);
    axios.post(`${url}/create`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',

        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch(error => reject(error));
  } else if (!name) {
		reject({
			status: 'error',
			info: 'name no esta definido',
		});
	}  else if (!description) {
		reject({
			status: 'error',
			info: 'description no esta definido',
		});
	}  else if (!normalprice) {
		reject({
			status: 'error',
			info: 'normalprice no esta definido',
		});
	}  else if (!disccountprice) {
		reject({
			status: 'error',
			info: 'disccountprice no esta definido',
		});
	}  else if (!sku) {
		reject({
			status: 'error',
			info: 'sku no esta definido',
		});
	}  else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});

export const updateproducts = ({
  token,
  _id,
	name,
	description,
	normalprice,
	disccountprice,
	status,
	sku,

}) => new Promise((resolve, reject) => {
  if (token && _id && name && description && normalprice && disccountprice && sku ) {
    axios.post(`${url}/update/${_id}`, {
		name,
		description,
		normalprice,
		disccountprice,
		status,
		sku,

    }, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error))
  } else if (!name) {
		reject({
			status: 'error',
			info: 'name no esta definido',
		});
	}  else if (!description) {
		reject({
			status: 'error',
			info: 'description no esta definido',
		});
	}  else if (!normalprice) {
		reject({
			status: 'error',
			info: 'normalprice no esta definido',
		});
	}  else if (!disccountprice) {
		reject({
			status: 'error',
			info: 'disccountprice no esta definido',
		});
	}  else if (!sku) {
		reject({
			status: 'error',
			info: 'sku no esta definido',
		});
	}  else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});

export const getAllproducts = ({
  token,
}) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}/list/basic/64d9168de0d023d6ff6ccc21`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});

export const getOneproducts = ({
  token,
  _id,
}) => new Promise((resolve, reject) => {
  if (token && _id) {
    axios.get(`${url}/one/${_id}`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!_id) {
    reject({
      status: 'error',
      info: 'Identificador no definido'
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});

export const deleteproducts = ({
  token,
  _id,
}) => new Promise((resolve, reject) => {
  if (token && _id) {
    axios.delete(`${url}/delete/${_id}`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!_id) {
    reject({
      status: 'error',
      info: 'Identificador no definido',
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
})

