
import {combineReducers} from 'redux';
import auth from './auth.reducer';
import shoppingcart from './shoppingcart.reducer';
import store from './store.reducer';

export default combineReducers({
  auth, shoppingcart, store
});

