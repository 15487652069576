
import React from 'react';

import { connect } from 'react-redux';
import LoftyEditorJsReadOnly from '../../components/LoftyEditorJsReadOnly';
import LoftyEditorJs from '../../components/LoftyEditorJs';



import './policies2.scss';


// Hamburger
function loftyInitHam() {
  try {
    // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}





const Ppolicies2 = ({ match, loftytoken, loftyuser, addLoftyUserApp, deleteLoftytokenApp, addLoftyTokenApp }) => {



  setTimeout(() => { loftyInitHam(); }, 2000);





  return (
    <div id="id64d9fe58925546452564742f" >

      <div id="ivee" className="">

        <a
          target=""
          className=""
          id="iei8fe"
          href="/"
        >

          <img
            className=""
            id="i1i8k"
            src="https://assetsprojects.s3.amazonaws.com/eewk3il9okodib.png"
            alt="undefined"
          />

        </a>

        <div id="" className="contentwrapperanimated ">

          <div id="" className="container_animated ">

            <label id="" className="menu__btn ">

              <span className="span_before " id="">

              </span>

              <span className="span_middle " id="">

              </span>

              <span className="span_after " id="">

              </span>

            </label>

            <label id="" className="menu__close__btn ">

              <span className="span__close__left " id="">

              </span>

              <span className="span__close__right " id="">

              </span>

            </label>

            <ul id="" className="menu__box ">

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/"
                  type=""
                >
                  <span>Home</span>
                </a>

              </li>

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/products"
                  type=""
                >
                  <span>Shop</span>
                </a>

              </li>

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/aboutus"
                  type=""
                >
                  <span>About Us</span>
                </a>

              </li>

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/cartpage"
                  type=""
                >
                  <span>Shopping Cart</span>
                </a>

              </li>

              <li className="linkshref " id="">

              </li>

            </ul>

          </div>

        </div>

        <div id="iq9l" className="">

          <a
            target=""
            className=""
            id="i5tql"
            href="/"
          >

            <div id="i1n85" className="">
              <span>Home</span>
            </div>

          </a>

          <a
            target=""
            className=""
            id="ifv1s"
            href="/products"
          >

            <div id="iqceg" className="">
              <span>Shop</span>
            </div>

          </a>

          <a
            target=""
            className=""
            id="ikkdy"
            href="/aboutus"
          >

            <div id="if57z" className="">
              <span>About Us</span>
            </div>

          </a>

          <a
            target=""
            className=""
            id="i1jyrj"
            href="/blog"
          >

            <div id="ijsj7y" className="">
              <span>Blog</span>
            </div>

          </a>

          <form
            id="islmj"
            className=""
            onSubmit={(e) => {
              e.preventDefault();
              const doc = {
                q: e.target.search.value,
              }
              const query = new URLSearchParams(doc);
              window.location.href = `/products?${query.toString()}`
            }}
          >

            <div id="irz9ej" className="">

              <input
                name="search"
                className=""
                id="i42p08"
              />

            </div>

            <button
              type="submit"
              name=""
              id="irfkh"
              className=""

            >

              <div id="ivb27" className="">
                <span>Search</span>
              </div>

            </button>

          </form>

          <div id="inqxd" className="">

            <a
              target=""
              className=""
              id="i6hs3"
              href="/cartpage"
            >

              <img
                className=""
                id="ijy2n"
                src="https://assetsprojects.s3.amazonaws.com/404p7nilj3fs1w7.png"
                alt="undefined"
              />

            </a>

          </div>

        </div>

      </div>

      <div id="ipezzh" className="">

        <div id="ivp3c" className="">

          <p className="" id="i0pfy">
            <span>Policies</span>
          </p>

        </div>

      </div>

      <div id="ihis35" className="">

        <div id="ixobj" className="">

          <div id="iml6i" className="">

            <p className="" id="i81kfv">

              <p className="MsoNormal " id="iy96i7">

                <b className="" id="">

                  <span className="" id="i0ddw4">
                    <span>FISHERMIND
                      LLC/THE COMPANY</span>
                  </span>

                </b>

                <span className="" id="i0mt38">
                  <span> owner of the SPOTTY TAG MOBIL
                    APPLICATION, at all time, as a responsible company, is in charge of protecting
                    the Personal Data requested on the Mobil App </span>
                  <b className="" id="">
                    <span>SPOTTY TAG</span>
                  </b>

                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="idu51i">

                <b className="" id="">

                  <span className="" id="ixj2pr">
                    <span>1.    PRIVACY POLICY GENERALS. </span>
                    <div id="" className="">

                    </div>

                  </span>

                </b>

              </p>

              <p className="MsoNormal " id="iy94v2">

                <span className="" id="ime19o">
                  <span>This Privacy Policy Notification explains our privacy
                    policy in order that the user can understand with certainty the manner in which
                    its personal information is used when using our Mobil application. When using
                    and navigating the Application, the user expressly and tacitly authorizes their
                    Personal Data to be handled. The Users Personal Data is collected legally and
                    never through deceit or fraudulent actions.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="ilx1ah">

                <span className="" id="iqdq1n">
                  <span>After reading this Privacy Notification, the user can
                    freely and voluntarily decide if it wishes to provide </span>
                  <b className="" id="">
                    <span>FISHERMIND LLC</span>
                  </b>
                  <span>, the Personal Data which might be required, or
                    obtained due to subscription to the </span>
                  <b className="" id="">
                    <span>SPOTTY
                      TAG APP</span>
                  </b>
                  <span>. In case you do not wish your Personal Data be used, you understand
                    and accept that you cannot use the Application.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="inh1ns">

                <b className="" id="">

                  <span className="" id="ifa5sj">
                    <span>FISHERMIND
                      LLC </span>
                  </span>

                </b>

                <span className="" id="ix37nk">
                  <span>reserves the right to make
                    modifications to its Privacy Notification for the use of the </span>
                  <b className="" id="">
                    <span>SPOTTY TAG APP</span>
                  </b>
                  <span> in order to adapt it to
                    new legal, jurisprudence requirements or to that which to its criteria allows
                    them to improve service and contents of the Application. This way this
                    Notification can be periodically updated. Thus, we invite you to regularly
                    review the Privacy Notification. In such cases, </span>
                  <b className="" id="">
                    <span>FISHERMIND LLC</span>
                  </b>
                  <span> will announce on the Application or through
                    electronic means, such as email, social media changes with reasonable
                    anticipation before they are put into practice.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="iiphyo">

                <b className="" id="">

                  <span className="" id="ifknh6">
                    <span>II.PROCESSING,
                      HANDLING AND USE OF PERSONA DATA.</span>
                  </span>

                </b>

                <span className="" id="i4nmpf">

                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="i86t8s">

                <span className="" id="ixntf5">
                  <span>For the purposes of Law and this Application, Personal
                    Data is understood as any information concerning a physically or identifiable
                    person. For the effects of this Privacy Notification, in an enunciatively, but
                    not limited manner, the following information collected will be understood as
                    “Personal Data”:</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="iqgxqw">

                <span className="" id="it6zfj">
                  <span>1. State or Federal ID</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="ir0v89">

                <span className="" id="ixji5a">
                  <span>2. Name(s) </span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="ilch2n">

                <span className="" id="i8am8g">
                  <span>3. Cellular Phone Number(s)</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="iuy11j">

                <span className="" id="igdp33">
                  <span>4. Land Lines </span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="i3s8aj">

                <span className="" id="i2eyqn">
                  <span>5. Gender</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="ib1rfb">

                <span className="" id="isa4hv">
                  <span>6. Email Address (es)</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="ighwes">

                <span className="" id="ix309y">
                  <span>7. Address (es)</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="iz7bmo">

                <span className="" id="innuju">
                  <span>8. Birth Dates</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="ib81r5">

                <span className="" id="i1ir9i">
                  <span>9. User’s Photos</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="ird1k7">

                <span className="" id="itdwoq">
                  <span>10. Users on social networks or digital platforms such
                    as: Facebook, Instagram, Google, and similar.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="ias8lv">

                <span className="" id="iqsuu8">

                </span>

              </p>

              <p className="MsoNormal " id="iyz716">

                <span className="" id="iffp0m">
                  <span>Your Personal Data will be predominantly processed
                    through electronic and automated means. In addition to the above mentioned
                    Personal Data, for information purposes in this Privacy Notification, </span>
                  <b className="" id="">
                    <span>FISHERMIND LLC </span>
                  </b>
                  <span>will not request
                    Personal Data considered to be sensitive, which require special protection,
                    thus, in case the user adds, publishes or shares Sensitive Personal Data
                    through the Application in a consistent manner, they will tacitly and expressly
                    accept them to be collected. In case this happens, </span>
                  <b className="" id="">
                    <span>FISHERMIND LLC</span>
                  </b>
                  <span> commits to process your Sensitive Personal Data with
                    the greatest diligence and protection. In spite of this, all users are urged
                    not to publish nor share Sensitive Personal Data within the Application.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="ieuh14">

                <span className="" id="i4xgkg">
                  <span>We inform you that your Personal Data may be shared
                    within and outside the country with the following people, organizations, or
                    authorities for the reasons described in this Privacy Notification:</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="im4p48">

                <span className="" id="i6bq28">
                  <span>1. Local or foreign persons, companies or professional
                    firms, which offer us help and consultancy as to accounting, administrative,
                    legal taxes and financial related to the supply of the Application.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="i56s5k">

                <span className="" id="i7y7kj">
                  <span>2. Individuals, entities or authorities, local or
                    foreign, for whom supplying them your personal data is mandatory according to
                    legal dispositions or orders from authorities.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="irdkzw">

                <span className="" id="ip8vjd">
                  <span>3. Individuals, delegates and/or those responsible,
                    local or foreign, to carry out activities strictly related to supplying the
                    service offered by the Application and its technical maintenance (including
                    network equipment and electronic communications networks).</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="idytl9">

                <span className="" id="ixkcpk">
                  <span>4. Local or foreign commercial partners, who provide
                    services in collaboration with </span>
                  <b className="" id="">
                    <span>FISHERMIND
                      LLC</span>
                  </b>

                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="iuq6tk">

                <span className="" id="i34gxa">
                  <span>5. Local and foreign competent Authorities.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="iqzqq1">

                <span className="" id="ibq0wa">
                  <span>6. New partners, collaborators, workers, employees,
                    local and foreign service providers related to the service of the Application.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="ig4g0b">

                <span className="" id="iron43">
                  <span>7. Any other third party related to </span>
                  <b className="" id="">
                    <span>FISHERMIND LLC </span>
                  </b>
                  <span>or who is directly
                    related to the </span>
                  <b className="" id="">
                    <span>SPOTTY TAG APP.</span>
                  </b>

                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="iyba8i">

                <span className="" id="iye7eu">

                </span>

              </p>

              <p className="MsoNormal " id="iu9hvf">

                <span className="" id="iowapj">
                  <span>III. </span>
                  <b className="" id="">
                    <span>PURPOSE OF
                      DATA COLLECTION.</span>
                  </b>

                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="i9o5t5">

                <span className="" id="ig1mj3">
                  <span>The purpose of automated collection and processing of
                    Personal Data is so that the user can use the Application appropriately.
                    FISHERMIND LLC, will collect Personal Data from the user in order to give
                    maintenance, management, administration, provision, extension and improvement
                    of the Applications services, to which the user has decided to subscribe and
                    register and use the adequacy of these services to preferences and tastes,
                    study of use of services, sending out updates on services, emitting through traditional
                    and electronic means technical, operational and commercial information as to
                    products and services supplied by the Application.  The purpose of collection and automated of
                    Personal Data also includes supplying survey forms, which you are not obligated
                    to respond.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="ie5tg2">

                <span className="" id="i2vywl">
                  <span>User guarantees that the Personal Data supplied to </span>
                  <b className="" id="">
                    <span>FISHERMIND LLC </span>
                  </b>
                  <span>through the </span>
                  <b className="" id="">
                    <span>SPOTTY TAG APP</span>
                  </b>
                  <span> Mobil application
                    through the Application registration form are true and is responsible for
                    notifying FISHERMIND LLC of any modification in the same.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="i2gwv2">

                <b className="" id="">

                  <span className="" id="ij7xay">
                    <span>TYPES OF
                      DATA COLLECTED</span>
                  </span>

                </b>

                <span className="" id="imtrsc">

                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="ikujlg">

                <span className="" id="iuc6rv">
                  <span>Among the types of Personal Data collected by this
                    Application, either directly or through third parties, are: </span>
                  <u className="" id="">
                    <span>Cookies: Usage
                      data; unique devise identifiers for advertising
                    </span>
                  </u>
                  <span>(Google or IDFA publicity ID, for example); email address;
                    geographic portion; language, distinct types of Data; username; password;
                    camera permit; microphone permit; permit for photo gallery; devise data,
                    geographical region number of users;
                    duration of session; direct purchases from the Application, amount of
                    times the Application is opened; Application update; initial execution of the
                    application; operation system. The complete information in reference to each
                    category of Personal Data collected are provided in the sections of this
                    privacy policy dedicated to this purpose or through explanatory texts shown
                    prior to collection of said Data.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="iqehos">

                <span className="" id="it75oi">
                  <span>Personal Data can be provided freely by the User, or
                    in the case of Usage Data, will be automatically collected when using this
                    application. All Data requested by this Application are mandatory and denial to
                    provide them will possibly make it impossible for the Application to proceed to
                    provide service. In cases where this Application specifically indicates that
                    certain Data is not mandatory, Users are free to abstain from filling them in
                    without this having any consequence on the availability and function of the
                    service.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="iphyj9">

                <span className="" id="iarste">
                  <span>Use of Cookies – or other follow-up tools – on behalf
                    of this Application or third-party service holders used by this Application its
                    purpose is to provide the service requested by the User, in addition to any
                    other purpose described in this document and in the Cookie Policy, in case it
                    is available.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="i5mr2z">

                <span className="" id="iw5ruh">
                  <span>The User assumes the responsibility as to Personal
                    Data of third parties collected, published or shared through this application
                    and through this that they have consent on behalf of said third parties to
                    provide said Data to the owner. </span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="inb2fr">

                <b className="" id="">

                  <span className="" id="i82a5t">
                    <span>SOCIAL
                      NETWORKS </span>
                  </span>

                </b>

                <span className="" id="i6o8dj">

                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="i3yqq5">

                <span className="" id="i0761h">
                  <span>Social networks (such as Facebook, Instagram, and
                    Twitter, among others) constitute a communications platform and interconnection
                    between digital platforms of the different users, they are separate from
                    FISHERMIND LLC and, thus, are not under its responsibility.  </span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="iqai7l">

                <span className="" id="inyijh">
                  <span>Although this SPOTTY TAG APP of FISHERMIND LLC tries
                    to provide links only to third party sites and applications that comply with
                    applicable laws and regulations and THE COMPANY standards, the user must
                    understand that FISHERMIND LLC has no control over nature, trustworthiness and
                    contents of those sites, thus providing the link does not mean that we are
                    recommending those sites, information they contain, or their products or
                    services or that of third parties recommended by them. THE COMPANY does not accept
                    the responsibility for the contents of the site of a third party with which
                    exists a hypertext link and does not guarantee (explicitly or implicitly) as to
                    the contents of the site’s information, being that it does not recommend those
                    sites. You must verify the terms and conditions sections, legal policy, and
                    privacy of the other sites which you visit, or those you link to. THE COMPANY
                    does not assume any responsibility, for direct or indirect loss or
                    consequential due to use of a third-party site.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="iap6zb">

                <span className="" id="izi0wl">
                  <span>Any Personal Data, photographs, images, personal
                    information revealed voluntarily through Social Networks or within the Mobil
                    SPOTTY TAG APP, will be available for any internet user and may be copied and
                    used by others, thus you are responsible for managing the use of your personal
                    data on those sites.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="itac3e">

                <span className="" id="igy78q">
                  <span>The User will be the sole and complete responsible
                    party for any content, modification, or update loaded or eliminated from the
                    SPOTTY TAG APP database, or sending emails, transmits, or makes available to
                    third parties through our services and will exclusively respond before third
                    parties for the contents of said information.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="iu9ior">

                <span className="" id="i4ndws">
                  <span>FISHERMIND LLC reserves the right to remove from the
                    platform any false, offensive, indecent or illegal content, without the need of
                    prior authorization from the User, as well as files, images and data which have
                    not been updated for a period of over a year.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="ilr32i">

                <span className="" id="ihqrm5">
                  <span>VI. PERMITS OVER DEVICES TO ACCESS PESONAL DATA     </span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="iex73s">

                <span className="" id="iivahm">
                  <span>Depending on the specific devise of the user, this
                    Application requires certain permits to access the data of the user’s devise,
                    as described below.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="igvrcg">

                <span className="" id="iu2o8f">
                  <span>By default, the User must grant these permissions
                    before being able to access the information in question. After granting
                    permission, the use may revoke it at any time. To revoke these permissions, the
                    user may use configuration options of the devise or else contact FISHERMIND LLC
                    to obtain its assistance through its contact information which appears on this
                    privacy notification document.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="i5hnle">

                <span className="" id="i90gs2">
                  <span>The concrete procedure to control permissions from the
                    application can depend on the user’s device, as well as the software it uses.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="iwmg1g">

                <span className="" id="im8qzh">
                  <span>It must be considered that revoking said permissions
                    might affect the correct function of this Application.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="i2xyc9">

                <span className="" id="i8gt82">
                  <span>If the User grants any of the permissions stated
                    below, it is authorizing this Application to process the corresponding Personal
                    Data.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="i98vtu">

                <span className="" id="icwp4i">
                  <span>1. Microphone permission: Permits to access and record
                    audio of user’s microphone device.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="iuoiek">

                <span className="" id="i9wn3d">
                  <span>2. Camera permission: Used to access camera or to
                    capture images and make video recordings from the device.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="img3sz">

                <span className="" id="ip9o1w">
                  <span>3. Photo Gallery permission: Allows access to User’s
                    Photo Gallery.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="irnduq">

                <span className="" id="iobqzq">
                  <span>4. Location permission: Allows the User to share its
                    exact or estimated location with the Mobil application. This application will
                    send notifications to the owner of the pet reported in the SPOTTY TAG Mobil App
                    as lost or stolen, in case a third party carries out a consultation with the
                    digital identification number assigned to the pet on the system database by
                    scanning the QR Code on the digital tag on the pet, or by entering on the app
                    the identification number which is visible on said tag; the data may include
                    the location of the devise which is carrying out the consultation in real time,
                    as long as the third party doing the consultation has authorized this option.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="iqbjx6">

                <span className="" id="i9ouxh">
                  <span>FISHERMIND LLC is not responsible for the security of
                    the User’s Smartphone equipment used to access the APP, or for the availability
                    of service on the devises on which the APP is downloaded.  In the manner allowed by law, the application
                    materials are provided without guarantee of any kind, either expressly or
                    implicitly, including without limitation satisfactory quality guarantees,
                    commercialization, adequacy for a particular purpose or none infraction, thus,
                    THE COMPANY does not guarantee adequate function on different operation
                    systems, or devises on which the application is used. To access the start menu
                    the user will have a Username and Code, which identifies him/her relationship
                    to the FISHERMIND LLC SPOTTY TAG APP which will be the same used on the Web
                    Portal.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="ifbnmb">

                <span className="" id="i1zedf">
                  <span>VII. UPDATES</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="iemf3w">

                <span className="" id="iy71jt">
                  <span>As the system and technologies advance, and new events
                    are generated, we will review and update the usage conditions of our platform
                    and Mobil Application.           </span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="ioiizx">

                <span className="" id="irru1l">
                  <span>In the same manner, as time goes by and our platform
                    grows also the need to innovate, change and improve services grow, thus we
                    reserve the right to add, or remove functions, characteristics, create or limit
                    services, suspend, or stop services temporarily or indefinitely without prior
                    notification to the users.  </span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="i5of2f">

                <span className="" id="ihjwhn">
                  <span>In the same manner the User voluntarily grants THE
                    COMPANY permission to update the software it uses or downloads. If you download
                    or use our software, you grant us permission to download or install versions
                    with improvements, updates and additional functions so as to continue its
                    improvement, perfectioning and development.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="inzlzw">

                <span className="" id="iraejl">
                  <span>VIII. CHARGES.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="i73pg4">

                <span className="" id="if2vvk">
                  <span>FISHERMIND LLC reserves the right to make charges for
                    the sales of goods, or for services it provides, or may provide in the future,
                    through its SPOTTY TAG APP Mobil application or on the web platform, and for
                    that it will only need to notify sending the corresponding notification to the
                    User prior to the beginning of the period in which it should apply the service
                    charge, where it will notify the details such as: type of goods or service,
                    amount to pay and period; to make use of some services, the user must have an
                    active account. It is the user’s responsibility to maintain their account
                    information complete and updated. The User will be responsible for maintaining
                    the confidentiality of its account, of the use it makes of it and restriction
                    to access its account, being that THE COMPANY will not carry out any type of
                    devolution in cash of species for purchases of goods and services made from
                    their User’s account.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="iuoixf">

                <span className="" id="ixu5gw">
                  <span>The Company may establish different payment mechanisms
                    for their clients, for which they can use the electronic platform of third
                    parties, who act as financial intermediates between the client and the Company,
                    however, before processing any order the Company will verify the availability of
                    funds by said intermediate to its account, in case the Company has not received
                    the corresponding payment the Company will not ship and the order will be
                    considered not carried out or cancelled.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="i6iofi">

                <span className="" id="ilkgwq">
                  <span>Shipping: The client may select between free shipping
                    through conventional postal service (which would take between 7 to 10 work
                    days), or urgent shipping through conventional postal service (which could be
                    between 3 to 5 work days for an additional fee which will be reflected when
                    completing the order.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="iq03gm">

                <span className="" id="imzm26">
                  <span>IX. TEMPORARY SUSPENSION OR PERMANENT CANCELLATION.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="io7y9u">

                <span className="" id="ib96wf">
                  <span>The User may eliminate its account at any time to
                    cease its relationship, as long as it is up-to-date with THE COMPANY. The
                    account can be cancelled after 1 year without updating the images or data. In
                    any case, you accept that, for technical motives, the contents eliminated can
                    remain on the platform as security copies for an indefinite period of time.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="ixitbi">

                <span className="" id="i9o4vf">
                  <span>Misuse or violation of our conditions or policies
                    authorizes us to take measures as to the account created by the User, which
                    could go from the suspension of access or in habilitation and cancellation of
                    the account. </span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="i5c91s">

                <span className="" id="iu8lbq">
                  <span>In case the User fails to comply with these Terms and
                    Conditions, or any other dispositions resulting from the application,
                    FISHERMIND LLC can suspend your access to SPOTTY TAG APP or its platform.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="i7yu5h">

                <span className="" id="ihtnic">
                  <span>Inappropriate use or any attempt to extract the source
                    code in our software or other confidential data of this platform will be
                    informed to the corresponding authorities. You may read and view material
                    related to products purchased. No part of the application can be reproduced,
                    transmitted or stored on another website or in another form of electronic
                    recovery system.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="ipldxe">

                <span className="" id="iydts7">
                  <span>FISHERMIND LLC does not interfere, nor make decisions,
                    nor guarantees, relationships which the users come to sustain, or links with
                    third parties, which support and promote their products and services on the
                    App. These third-party brands are used only to identify products and services
                    of their corresponding owners, and the promotion, sponsorship, spreading by
                    FISHERMIND LLC, on the application or platform, should not interfere with the
                    use of these commercial brands.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="i4pgtv">

                <span className="" id="ivkkzx">
                  <span>The User is responsible for any activity made with
                    their account or Username; activities of the accounts remain registered to
                    protect all our user community. User must keep its password confidential, if by
                    any cause it is shared or gives access to another user and this user makes any
                    kind of offense to these terms you may inevitably lose your account and will be
                    responsible for responding before third parties for said offenses.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="iflrkx">

                <span className="" id="iewlrm">
                  <span>X. THE COMPANY’S INTELECTUAL PROPERTY.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="ifgjce">

                <span className="" id="isiw9v">
                  <span>FISHERMIND LLC respects intellectual property laws and
                    expects its users do the same; any offense from our users to the laws
                    authorizes us to deactivate or cancel their account, including informing the
                    corresponding authorities of the account owners who infringe precepts. </span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="i2p3r2">

                <span className="" id="i5udb2">
                  <span>We reserve all rights of the content protected by
                    intellectual and industrial property rights (of our property) which we make
                    available in our application and database, such as image, logos, designs,
                    procedure manuals, algorithms, etc. FISHERMIND LLC may sell its products
                    through third party commercial establishments </span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="irhzir">

                <span className="" id="ikgxfi">
                  <span>XI. INDIVIDUAL O COMPANIES SANCTIONED BY THE
                    GOVERNMENT OF THE COUNTRY WHERE SERVICE IS PROVIDED OR THE US DEPARTMENT OF
                    TREASURY.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="iaulsh">

                <span className="" id="iicogo">
                  <span>Our headquarters is located in the United States of
                    America. If the user is an individual or company sanctioned by the Department
                    of State, of the United States of America no type of commercial ties will be
                    established with them nor granting of service, thus we request them to abstain
                    from using our service.</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="iuxdld">

                <span className="" id="i3zha4">
                  <span> Contact us:</span>
                  <div id="" className="">

                  </div>

                </span>

              </p>

              <p className="MsoNormal " id="il3ym3">

                <span className="" id="i3l3hw">
                  <span>If you have any questions, comments or suggestions as
                    to our Privacy Policy, you may write us at our Headquarters email address: </span>
                </span>

                <span className="" id="ie7udi">
                  <span>customerservice@spottytag.com.</span>
                </span>

                <span className="" id="iw7upp">

                </span>

                <span className="" id="idpc33">

                  <div id="" className="">

                  </div>

                </span>

              </p>

            </p>

          </div>

        </div>

      </div>

      <div id="ie6sa" className="">

        <div id="iqobg" className="">

          <p className="" id="ibllc">
            <span>Contact us for more information!</span>
            <br className="" id="" />

          </p>

          <div id="ihuk4" className="">

            <div id="iu87j" className="">

              <img
                className=""
                id="i67f"
                src="https://assetsprojects.s3.amazonaws.com/eewk3il9okodib.png"
                alt="undefined"
              />

            </div>

            <div id="iwg4f" className="">

              <a
                target="_blank"
                className=""
                id="irgjju"
                href="https://www.instagram.com/spottytaghn/"
              >

                <img
                  className=""
                  id="iqcul1"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hafi3.png"
                  alt="undefined"
                />

                <p className="" id="iqq4mu">
                  <span>@spottytaghn</span>
                </p>

              </a>

              <a
                target="_blank"
                className=""
                id="i5vgyt"
                href="https://www.facebook.com/spottytaghn"
              >

                <img
                  className=""
                  id="iae27s"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3he6f6.png"
                  alt="undefined"
                />

                <p className="" id="ikdl4l">
                  <span>spottytaghn</span>
                </p>

              </a>

              <a
                target=""
                className=""
                id="ii2lsw"
                href="https://api.whatsapp.com/send?phone=50433027674"
              >

                <img
                  className=""
                  id="iu0rp5"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hfpjb.png"
                  alt="undefined"
                />

                <p className="" id="iqzql2">
                  <span>+504 3302-7674</span>
                </p>

              </a>

              <a
                target=""
                className=""
                id="i0mmmx"
                href="mailto:customerservice@spottytag.com"
              >

                <img
                  className=""
                  id="ih8ro2"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hg2uk.png"
                  alt="undefined"
                />

                <p className="" id="ivdrgi">
                  <span>customerservice@spottytag.com</span>
                </p>

              </a>

            </div>

          </div>

        </div>

      </div>

      <div id="ig891" className="">

        <p className="" id="i7l1k">
          <span>©2023 | spotty tag hn | privacy policies | terms of use</span>
          <br className="" id="" />

        </p>

      </div>

    </div>
  );
}

const mapStateToProps = (state) => state.auth;



export default connect(mapStateToProps)(Ppolicies2);
