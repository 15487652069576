
import axios from '../config/axios';

export const url = '/api/store'

export const createstore = ({
  token,
	identificator,
	name,
	email,

}) => new Promise((resolve, reject) => {
  if (token && identificator && name && email ) {

    axios.post(`${url}/create`, {
		identificator,
		name,
		email,

    }, {
      headers: {
        
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch(error => reject(error));
  } else if (!identificator) {
		reject({
			status: 'error',
			info: 'identificator no esta definido',
		});
	}  else if (!name) {
		reject({
			status: 'error',
			info: 'name no esta definido',
		});
	}  else if (!email) {
		reject({
			status: 'error',
			info: 'email no esta definido',
		});
	}  else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});

export const updatestore = ({
  token,
  _id,
	identificator,
	name,
	email,

}) => new Promise((resolve, reject) => {
  if (token && _id && identificator && name && email ) {
    axios.post(`${url}/update/${_id}`, {
		identificator,
		name,
		email,

    }, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error))
  } else if (!identificator) {
		reject({
			status: 'error',
			info: 'identificator no esta definido',
		});
	}  else if (!name) {
		reject({
			status: 'error',
			info: 'name no esta definido',
		});
	}  else if (!email) {
		reject({
			status: 'error',
			info: 'email no esta definido',
		});
	}  else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});

export const getAllstore = () => new Promise((resolve, reject) => {
  axios.get(`${url}/list/basic/64f9d23f65f10c3079b64fba`, {
  }).then((res) => {
    const {data} = res;
    if (data.status === 'success') {
      resolve(data);
    } else {
      reject(data);
    }
  }).catch((error) => reject(error));
});

export const getOnestore = ({
  token,
  _id,
}) => new Promise((resolve, reject) => {
  if (token && _id) {
    axios.get(`${url}/one/${_id}`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!_id) {
    reject({
      status: 'error',
      info: 'Identificador no definido'
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});

export const deletestore = ({
  token,
  _id,
}) => new Promise((resolve, reject) => {
  if (token && _id) {
    axios.delete(`${url}/delete/${_id}`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!_id) {
    reject({
      status: 'error',
      info: 'Identificador no definido',
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
})

