
import React from 'react';

import {connect} from 'react-redux';
import LoftyEditorJsReadOnly from '../../components/LoftyEditorJsReadOnly';
import LoftyEditorJs from '../../components/LoftyEditorJs';



import './error404.scss';






const Perror404 = ({match, loftytoken, loftyuser, addLoftyUserApp, deleteLoftytokenApp, addLoftyTokenApp}) => {
  
  
  
  
  
  

  
  return (
    <div id="id64eebf329255464525648cf0" >

      <div id="iklu" className="">
      
      <div id="igf2" className="">
      
      <p className="" id="id3m">
        <span>Error 404</span>
      </p>
      
      <div id="ipea" className="">
      
      <img
        className=""
        id="i1fj"
        src="https://assetsprojects.s3.amazonaws.com/eewk3il9okodib.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="i4zog" className="">
      
        <a
          target=""
          className=""
          id="i867o"
          href="/"
        >
          
    <div id="ilbt9" className="">
    <span>Go Home</span>
    </div>
    
        </a>
        
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;



export default connect(mapStateToProps)(Perror404);
  