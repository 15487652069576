
import axios from '../config/axios';

export const url = '/api/item'


export const createitem = ({
  token,
	description,
	type,
	idTransmision,
	idCountry,
	city,
	idUser,
	gender,
	lost,
	color,
	numberMicroship,
	colorcast,
	species,
	idVeterinaryClinic,
	extraInformation,
	medicines,
	allergies,
	phone1,
	phone2,
	idState,
	vaccines,
	surgeries,
	childbirth,
	pedigree,
	dewormers,
	fractures,
	scars,
	reward,
	brand,
	imei,
	age,
	validItem,
	numberPlate,
	haveReward,
	textModel,
	idPdf,
	weight,
	dateVeterinary,

}) => new Promise((resolve, reject) => {
  if (token && description && type && city && idUser && idState ) {

    axios.post(`${url}//create/basic/621c06fe6bef5b00160bf4b9`, {
		description,
		type,
		idTransmision,
		idCountry,
		city,
		idUser,
		gender,
		lost,
		color,
		numberMicroship,
		colorcast,
		species,
		idVeterinaryClinic,
		extraInformation,
		medicines,
		allergies,
		phone1,
		phone2,
		idState,
		vaccines,
		surgeries,
		childbirth,
		pedigree,
		dewormers,
		fractures,
		scars,
		reward,
		brand,
		imei,
		age,
		validItem,
		numberPlate,
		haveReward,
		textModel,
		idPdf,
		weight,
		dateVeterinary,

    }, {
      headers: {
        
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch(error => reject(error));
  } else if (!description) {
		reject({
			status: 'error',
			info: 'description no esta definido',
		});
	}  else if (!type) {
		reject({
			status: 'error',
			info: 'type no esta definido',
		});
	}  else if (!city) {
		reject({
			status: 'error',
			info: 'city no esta definido',
		});
	}  else if (!idUser) {
		reject({
			status: 'error',
			info: 'idUser no esta definido',
		});
	}  else if (!idState) {
		reject({
			status: 'error',
			info: 'idState no esta definido',
		});
	}  else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});
      
export const updateitem = ({
  token,
  _id,
	description,
	type,
	idTransmision,
	idCountry,
	city,
	idUser,
	gender,
	lost,
	color,
	numberMicroship,
	colorcast,
	species,
	idVeterinaryClinic,
	extraInformation,
	medicines,
	allergies,
	phone1,
	phone2,
	idState,
	vaccines,
	surgeries,
	childbirth,
	pedigree,
	dewormers,
	fractures,
	scars,
	reward,
	brand,
	imei,
	age,
	validItem,
	numberPlate,
	haveReward,
	textModel,
	idPdf,
	weight,
	dateVeterinary,

}) => new Promise((resolve, reject) => {
  if (token && _id && description && type && city && idUser && idState ) {
    axios.post(`${url}//update/basic/621c06fe6bef5b00160bf4ba/${_id}`, {
		description,
		type,
		idTransmision,
		idCountry,
		city,
		idUser,
		gender,
		lost,
		color,
		numberMicroship,
		colorcast,
		species,
		idVeterinaryClinic,
		extraInformation,
		medicines,
		allergies,
		phone1,
		phone2,
		idState,
		vaccines,
		surgeries,
		childbirth,
		pedigree,
		dewormers,
		fractures,
		scars,
		reward,
		brand,
		imei,
		age,
		validItem,
		numberPlate,
		haveReward,
		textModel,
		idPdf,
		weight,
		dateVeterinary,

    }, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error))
  } else if (!description) {
		reject({
			status: 'error',
			info: 'description no esta definido',
		});
	}  else if (!type) {
		reject({
			status: 'error',
			info: 'type no esta definido',
		});
	}  else if (!city) {
		reject({
			status: 'error',
			info: 'city no esta definido',
		});
	}  else if (!idUser) {
		reject({
			status: 'error',
			info: 'idUser no esta definido',
		});
	}  else if (!idState) {
		reject({
			status: 'error',
			info: 'idState no esta definido',
		});
	}  else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});
      
export const getAllitem = ({
  token,
}) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}//list/basic/621c06fe6bef5b00160bf4bb`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});
      
export const getOneitem = ({
  token,
  _id,
}) => new Promise((resolve, reject) => {
  if (token && _id) {
    axios.get(`${url}//one/basic/621c06fe6bef5b00160bf4bc/${_id}`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!_id) {
    reject({
      status: 'error',
      info: 'Identificador no definido'
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});
      
export const deleteitem = ({
  token,
  _id,
}) => new Promise((resolve, reject) => {
  if (token && _id) {
    axios.delete(`${url}//delete/basic/6274922c09293c0016c09e1a/${_id}`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!_id) {
    reject({
      status: 'error',
      info: 'Identificador no definido',
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
})
      
export const getByNumberPlate = ({
	numberPlate
}) => new Promise((resolve, reject) => {
	if (numberPlate) {
		axios.get(`${url}/one/pet/${numberPlate}`)
			.then((res) => {
				const {data} = res;
				if (data.status === 'success') {
					resolve(data);
				} else {
					reject(data);
				}
			}).catch((error) => reject(error));
	} else {
		reject({
			status: 'error',
			info: 'Número de placa no definido'
		})
	}
});
