
import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { getOneorden } from '../../api/orden.api';
import { getAllproductorden } from '../../api/productorden.api';
import { getAllproducts } from '../../api/products.api';
import { getOnecountry } from '../../api/country.api';
import { getOnestate } from '../../api/state.api';

import './ordersummary.scss';


// Hamburger
function loftyInitHam() {
  try {
    // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}





const Pordersummary = ({ match, loftytoken, loftyuser, addLoftyUserApp }) => {

  const [orden, setOrden] = useState({});
  const [dataProduct, setDataProduct] = useState([]);
  const [country, setCountry] = useState({});
  const [state, setState] = useState({});

  setTimeout(() => { loftyInitHam(); }, 2000);

  useEffect(() => {
    const handleData = async () => {
      try {
        const res = await getOneorden({ token: 'asdsa', _id: match.params.id })
        setOrden(res.data);
        // obteniendo el pais
        const resCountry = await getOnecountry({ token: 'sdas', _id: res.data.idcountry });
        setCountry(resCountry.data);
        // obteniendo el estado
        const resState = await getOnestate({token: 'as', _id: res.data.idstate});
        setState(resState.data);
        // obteniendo los productos
        const resInfoProduct = await getAllproducts({token: 'asd'});
        const resProduct = await getAllproductorden({token: 'dsadas'});
        const newProduct = [];
        resProduct.data.forEach((product) => {
          if (product.order === match.params.id) {
            const info = resInfoProduct.data.find((info) => info._id === product.producto);
            newProduct.push({
              product, info
            });
          }
        });
        setDataProduct(newProduct);
      } catch (error) {

      }
    }
    handleData();
  }, [])

  console.log(dataProduct);

  return (
    <div id="id64da0c659255464525647608" >

      <div id="ivee" className="">

        <a
          target=""
          className=""
          id="iwlywh"
          href="/"
        >

          <img
            className=""
            id="i1i8k"
            src="https://assetsprojects.s3.amazonaws.com/eewk3il9okodib.png"
            alt="undefined"
          />

        </a>

        <a
          target=""
          className="shopping-container-mobile"
          id="i6hs3"
          href="/cartpage"
        >

          <img
            className=""
            id="ijy2n"
            src="https://assetsprojects.s3.amazonaws.com/406racilqpyblxz.png"
            alt="undefined"
          />

        </a>

        <div id="" className="contentwrapperanimated ">

          <div id="" className="container_animated ">

            <label id="" className="menu__btn ">

              <span className="span_before " id="">

              </span>

              <span className="span_middle " id="">

              </span>

              <span className="span_after " id="">

              </span>

            </label>

            <label id="" className="menu__close__btn ">

              <span className="span__close__left " id="">

              </span>

              <span className="span__close__right " id="">

              </span>

            </label>

            <ul id="" className="menu__box ">

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/"
                  type=""
                >
                  <span>Home</span>
                </a>

              </li>

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/products"
                  type=""
                >
                  <span>Shop</span>
                </a>

              </li>

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/aboutus"
                  type=""
                >
                  <span>About Us</span>
                </a>

              </li>

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/cartpage"
                  type=""
                >
                  <span>Shopping Cart</span>
                </a>

              </li>

              <li className="linkshref " id="">

              </li>

            </ul>

          </div>

        </div>

        <div id="iq9l" className="">

          <a
            target=""
            className=""
            id="i5tql"
            href="/"
          >

            <div id="i1n85" className="">
              <span>Home</span>
            </div>

          </a>

          <a
            target=""
            className=""
            id="ifv1s"
            href="/products"
          >

            <div id="iqceg" className="">
              <span>Shop</span>
            </div>

          </a>

          <a
            target=""
            className=""
            id="ikkdy"
            href="/aboutus"
          >

            <div id="if57z" className="">
              <span>About Us</span>
            </div>

          </a>

          <a
            target=""
            className=""
            id="i0hj6k"
            href="/blog"
          >

            <div id="ivd9wj" className="">
              <span>Blog</span>
            </div>

          </a>

          <form
            id="islmj"
            className=""
            onSubmit={(e) => {
              e.preventDefault();
              const doc = {
                q: e.target.search.value,
              }
              const query = new URLSearchParams(doc);
              window.location.href = `/products?${query.toString()}`
            }}
          >

            <div id="irz9ej" className="">

              <input
                name="search"
                className=""
                id="i42p08"
              />

            </div>

            <button
              type="submit"
              name=""
              id="irfkh"
              className=""

            >

              <div id="ivb27" className="">
                <span>Search</span>
              </div>

            </button>

          </form>

          <div id="inqxd" className="">

            <a
              target=""
              className=""
              id="i6hs3"
              href="/cartpage"
            >

              <img
                className=""
                id="ijy2n"
                src="https://assetsprojects.s3.amazonaws.com/406racilqpyblxz.png"
                alt="undefined"
              />

            </a>

          </div>

        </div>

      </div>

      <div id="ipezzh" className="">

      </div>

      <div id="ihis35" className="">

        <div id="ipdx1n" className="">

          <div id="iud3s7" className="">

            <p className="" id="icco8t">
              <span>Order Summary</span>
            </p>

            <div id="" className="formContainer ">

              <div id="i0smny" className="">

                <div id="ixb94j" className="">

                  <p className="" id="instrk">
                    <span>Id: </span>
                  </p>

                  <p className="" id="iug4ed">
                    <span>
                      {orden._id}
                    </span>
                  </p>

                </div>

                <div id="i9uo38" className="">

                  <p className="" id="iifa4y">
                    <span>Status: </span>
                  </p>

                  <p className="" id="ilpqea">
                    <span>
                      {orden.status}
                    </span>
                  </p>

                </div>

                <div id="i342v3" className="">

                  <p className="" id="ivw5vf">
                    <span>email: </span>
                  </p>

                  <p className="" id="ilz0fz">
                    <span>
                      {orden.email}
                    </span>
                  </p>

                </div>

                <div id="itp34g" className="">

                  <p className="" id="iawiom">
                    <span>Country:</span>
                  </p>

                  <p className="" id="inh8hw">
                    <span>
                      {country.name}
                    </span>
                  </p>

                </div>

                <div id="i2mq8x" className="">

                  <p className="" id="iieo1u">
                    <span>State:</span>
                  </p>

                  <p className="" id="ixsvp4">
                    <span>
                      {state.name}
                    </span>
                  </p>

                </div>

                <div id="ikgaze" className="">

                  <p className="" id="iglue2">
                    <span>City: </span>
                  </p>

                  <p className="" id="i7fxtx">
                    <span>
                      {orden.city}
                    </span>
                  </p>

                </div>

                <div id="imx36n" className="">

                  <p className="" id="ix4jyi">
                    <span>Address:</span>
                  </p>

                  <p className="" id="iz6a8i">
                    <span>
                      {orden.address}
                    </span>
                  </p>

                </div>

                {/* <div id="i92h23" className="">

                  <p className="" id="illr3e">
                    <span>Zip Code:</span>
                  </p>

                  <p className="" id="i215pj">
                    <span>
                      {orden.zipCode}
                    </span>
                  </p>

                </div> */}

              </div>

              <div>
                <h3 id='ix4jyi' style={{textAlign: 'center', width: '100%'}}>
                  Products
                </h3>
                {
                  dataProduct.map((item) => (
                    <div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
                      <div>
                        <p>
                          <b>Pet name:</b>
                          {' '}
                          {item.product.namepets}
                        </p>
                        <p>
                          <b>Phone Number:</b>
                          {' '}
                          {item.product.telephone	}
                        </p>
                        <p>
                          <b>Price:</b>
                          {' $'}
                          {item.product.price.toFixed(2)}
                        </p>
                      </div>
                      <div>
                        <img style={{width: 250}} alt='producto' src={item.info.loftyUrl} />
                      </div>
                    </div>
                  ))
                }
              </div>

              <form
                id="idie71"
                className=""

              >

                <div id="" className="title ">

                  <i className="fas fa-pencil-alt " id="">

                  </i>

                </div>

                <div id="" className="fieldsContainer ">

                  <div id="ig0q0j" className="">

                    <div id="iosfog" className="">

                      <p className="" id="iro57v">
                        <span>Subtotal:</span>
                      </p>

                      <p className="" id="ig3dpu">
                        <span>${`${orden.subtotal?.toFixed(2)}`}</span>
                      </p>

                    </div>

                    <div id="ie1c0n" className="">

                      <p className="" id="itbho8">
                        <span>Discount:</span>
                      </p>

                      <p className="" id="i6maui">
                        <span>${`${orden.discount?.toFixed(2)}`}</span>
                      </p>

                    </div>

                    <div id="i5me7j" className="">

                      <p className="" id="ifzmmq">
                        <span>Total:</span>
                      </p>

                      <p className="" id="icyaco">
                        <span>${`${parseFloat(orden.total).toFixed(2)}`}</span>
                      </p>

                    </div>

                  </div>

                </div>

              </form>

            </div>

          </div>

          <div id="ib241h" className="">

            <p className="" id="iq990n">
              <span>PAYMENT INFORMATION</span>
            </p>

            <div id="" className="formContainer ">

              <form
                id=""
                className=""

              >

                <div id="" className="title ">

                  <i className="fas fa-pencil-alt " id="">

                  </i>

                </div>

                <div id="" className="fieldsContainer ">

                  <div id="iqweg8" className="">

                    <div id="ivd9n9" className="">

                      <div id="i9vgjp" className="">
                        <span>Name</span>
                      </div>

                      <input
                        name=""
                        className="componentInput "
                        id=""
                      />

                    </div>

                    <div id="iq0ga3" className="">

                      <div id="iq3ifc" className="">
                        <span>Type Card</span>
                      </div>

                      <input
                        name=""
                        className="componentInput "
                        id=""
                      />

                    </div>

                    <div id="iix18y" className="">

                      <div id="iiu0oq" className="">
                        <span>Card Number</span>
                      </div>

                      <input
                        name=""
                        className="componentInput "
                        id=""
                      />

                    </div>

                  </div>

                  <div id="ils4ts" className="">

                    <div id="iuyegd" className="">

                      <div id="igohcl" className="">
                        <span>Expiration (MMYY)</span>
                      </div>

                      <input
                        name=""
                        className="componentInput "
                        id=""
                      />

                    </div>

                    <div id="i6jeuz" className="">

                      <div id="ipq6dx" className="">
                        <span>CVV</span>
                      </div>

                      <input
                        name=""
                        className="componentInput "
                        id=""
                      />

                    </div>

                  </div>

                  <div id="ig8syl" className="">

                    <button
                      type=""
                      name=""
                      id="i887bi"
                      className="standard_button "

                    >

                      <div id="i6irzp" className="">
                        <span>Realizar Pago</span>
                      </div>

                    </button>

                  </div>

                </div>

              </form>

            </div>

          </div>

        </div>

      </div>

      <div id="ie6sa" className="">

        <div id="iqobg" className="">

          <p className="" id="ibllc">
            <span>Contact us for more information!</span>
            <div id="" className="">

            </div>

          </p>

          <div id="ihuk4" className="">

            <div id="iu87j" className="">

              <img
                className=""
                id="i67f"
                src="https://assetsprojects.s3.amazonaws.com/eewk3il9okodib.png"
                alt="undefined"
              />

            </div>

            <div id="iwg4f" className="">

              <a
                target="_blank"
                className=""
                id="irgjju"
                href="https://www.instagram.com/spottytaghn/"
              >

                <img
                  className=""
                  id="iqcul1"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hafi3.png"
                  alt="undefined"
                />

                <p className="" id="iqq4mu">
                  <span>@spottytaghn</span>
                </p>

              </a>

              <a
                target="_blank"
                className=""
                id="i5vgyt"
                href="https://www.facebook.com/spottytaghn"
              >

                <img
                  className=""
                  id="iae27s"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3he6f6.png"
                  alt="undefined"
                />

                <p className="" id="ikdl4l">
                  <span>spottytaghn</span>
                </p>

              </a>

              <a
                target=""
                className=""
                id="ii2lsw"
                href="https://api.whatsapp.com/send?phone=50433027674"
              >

                <img
                  className=""
                  id="iu0rp5"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hfpjb.png"
                  alt="undefined"
                />

                <p className="" id="iqzql2">
                  <span>+504 3302-7674</span>
                </p>

              </a>

              <a
                target=""
                className=""
                id="i0mmmx"
                href="mailto:customerservice@spottytag.com"
              >

                <img
                  className=""
                  id="ih8ro2"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hg2uk.png"
                  alt="undefined"
                />

                <p className="" id="ivdrgi">
                  <span>customerservice@spottytag.com</span>
                </p>

              </a>

            </div>

          </div>

        </div>

      </div>

      <div id="ig891" className="">

        <p className="" id="i7l1k">
          <span>©2023 | spotty tag hn | privacy policies | terms of use</span>
          <div id="" className="">

          </div>

        </p>

      </div>

    </div>
  );
}

const mapStateToProps = (state) => state.auth;



export default connect(mapStateToProps)(Pordersummary);
