
import {ADD_PRODUCT_SHOPPING} from '../types/shoppingcart.types';

export const addLoftyShoppingCart = ({loftyShoppingCart}) => {
  localStorage.setItem('loftyShoppingCart', JSON.stringify(loftyShoppingCart));
  return {
    type: ADD_PRODUCT_SHOPPING,
    payload: {
      loftyShoppingCart,
    },
  };
};
