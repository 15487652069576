
import React from 'react';

import { connect } from 'react-redux';

import { addLoftyToken } from '../../actions/auth.actions';
import { loftyLoginWithEmail, loftyCreateUser, loftyCreateResetToken, loftyResetPassword } from '../../api/user.api';


import './resetpassword.scss';


// Hamburger
function loftyInitHam() {
  try {
    // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}





const Presetpassword = ({ match, loftytoken, loftyuser, addLoftyTokenApp }) => {



  setTimeout(() => { loftyInitHam(); }, 2000);




  return (
    <div id="id64e1032f9255464525648659" >

      <div id="ivee" className="">

        <a
          target=""
          className=""
          id="iwlywh"
          href="/"
        >

          <img
            className=""
            id="i1i8k"
            src="https://assetsprojects.s3.amazonaws.com/eewk3il9okodib.png"
            alt="undefined"
          />

        </a>

        <div id="" className="contentwrapperanimated ">

          <div id="" className="container_animated ">

            <label id="" className="menu__btn ">

              <span className="span_before " id="">

              </span>

              <span className="span_middle " id="">

              </span>

              <span className="span_after " id="">

              </span>

            </label>

            <label id="" className="menu__close__btn ">

              <span className="span__close__left " id="">

              </span>

              <span className="span__close__right " id="">

              </span>

            </label>

            <ul id="" className="menu__box ">

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/"
                  type=""
                >
                  <span>Home</span>
                </a>

              </li>

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="#"
                  type=""
                >
                  <span>Shop</span>
                </a>

              </li>

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="#"
                  type=""
                >
                  <span>About Us</span>
                </a>

              </li>

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="#"
                  type=""
                >
                  <span>Shopping Cart</span>
                </a>

              </li>

              <li className="linkshref " id="">

              </li>

            </ul>

          </div>

        </div>

        <div id="iq9l" className="">

          <a
            target=""
            className=""
            id="i5tql"
            href="/"
          >

            <div id="i1n85" className="">
              <span>Home</span>
            </div>

          </a>

          <a
            target=""
            className=""
            id="ifv1s"
            href="/products"
          >

            <div id="iqceg" className="">
              <span>Shop</span>
            </div>

          </a>

          <a
            target=""
            className=""
            id="ikkdy"
            href="/aboutus"
          >

            <div id="if57z" className="">
              <span>About Us</span>
            </div>

          </a>

          <a
            target=""
            className=""
            id="i1jyrj"
            href="/blog"
          >

            <div id="ijsj7y" className="">
              <span>Blog</span>
            </div>

          </a>


          <form
            id="islmj"
            className=""
            onSubmit={(e) => {
              e.preventDefault();
              const doc = {
                q: e.target.search.value,
              }
              const query = new URLSearchParams(doc);
              window.location.href = `/products?${query.toString()}`
            }}
          >

            <div id="irz9ej" className="">

              <input
                name="search"
                className=""
                id="i42p08"
              />

            </div>

            <button
              type="submit"
              name=""
              id="irfkh"
              className=""

            >

              <div id="ivb27" className="">
                <span>Search</span>
              </div>

            </button>

          </form>

          <div id="inqxd" className="">

            <a
              target=""
              className=""
              id="i6hs3"
              href="/cartpage"
            >

              <img
                className=""
                id="ijy2n"
                src="https://assetsprojects.s3.amazonaws.com/404p7nilj3fs1w7.png"
                alt="undefined"
              />

            </a>

          </div>

        </div>

      </div>

      <div id="ixt1bi" className="">

      </div>

      <div id="ihis35" className="">

        <div id="ipdx1n" className="">

          <div id="iud3s7" className="">

            <p className="" id="icco8t">
              <span>Reset Password</span>
            </p>

            <div id="iuml6c" className="">

              <form
                id="is79so"
                className=""
                onSubmit={async (e) => {
                  e.preventDefault();
                  try {
                    const loftyPassword = e.target.newPassword?.value;
                    const loftyConfirmPassword = e.target.confirmPassword?.value
                    await loftyResetPassword({ password: loftyPassword, confirmPassword: loftyConfirmPassword, token: match.params.id });
                    alert('Success!! go to the app')
                  } catch (error) {
                    console.log(error);
                    if (error.info) {
                      alert(error.info);
                    } else {
                      alert('Error al procesar los datos, por favor contacte con el servicio al cliente');
                    }
                  }
                }}

              >

                <div id="izw4wr" className="containerreset ">

                  <div id="" className="">

                  </div>

                  <input
                    name="newPassword"
                    className="inputreset "
                    id="ihtkmu"
                  />

                </div>

                <div id="izlps8" className="containerreset ">

                  <div id="" className="">

                  </div>

                  <input
                    name="confirmPassword"
                    className="inputreset "
                    id="i0k1df"
                  />

                </div>

                <div id="il0wxw" className="">

                  <button
                    type="submit"
                    name=""
                    id="imlvmh"
                    className=""

                  >
                    <span>
                      Submit
                    </span>
                  </button>

                </div>

              </form>

            </div>

          </div>

          <div id="ib241h" className="">

            <p className="" id="iq990n">
              <span>PAYMENT INFORMATION</span>
            </p>

            <div id="" className="formContainer ">

              <form
                id=""
                className=""

              >

                <div id="" className="title ">

                  <i className="fas fa-pencil-alt " id="">

                  </i>

                </div>

                <div id="" className="fieldsContainer ">

                  <div id="iqweg8" className="">

                    <div id="ivd9n9" className="">

                      <div id="i9vgjp" className="">
                        <span>Name</span>
                      </div>

                      <input
                        name=""
                        className="componentInput "
                        id=""
                      />

                    </div>

                    <div id="iq0ga3" className="">

                      <div id="iq3ifc" className="">
                        <span>Type Card</span>
                      </div>

                      <input
                        name=""
                        className="componentInput "
                        id=""
                      />

                    </div>

                    <div id="iix18y" className="">

                      <div id="iiu0oq" className="">
                        <span>Card Number</span>
                      </div>

                      <input
                        name=""
                        className="componentInput "
                        id=""
                      />

                    </div>

                  </div>

                  <div id="ils4ts" className="">

                    <div id="iuyegd" className="">

                      <div id="igohcl" className="">
                        <span>Expiration (MMYY)</span>
                      </div>

                      <input
                        name=""
                        className="componentInput "
                        id=""
                      />

                    </div>

                    <div id="i6jeuz" className="">

                      <div id="ipq6dx" className="">
                        <span>CVV</span>
                      </div>

                      <input
                        name=""
                        className="componentInput "
                        id=""
                      />

                    </div>

                  </div>

                  <div id="ig8syl" className="">

                    <button
                      type=""
                      name=""
                      id="i887bi"
                      className="standard_button "

                    >

                      <div id="i6irzp" className="">
                        <span>Realizar Pago</span>
                      </div>

                    </button>

                  </div>

                </div>

              </form>

            </div>

          </div>

        </div>

      </div>

      <div id="ipezzh" className="">

      </div>

      <div id="ie6sa" className="">

        <div id="iqobg" className="">

          <p className="" id="ibllc">
            <span>Contact us for more information!</span>
            <div id="" className="">

            </div>

          </p>

          <div id="ihuk4" className="">

            <div id="iu87j" className="">

              <img
                className=""
                id="i67f"
                src="https://assetsprojects.s3.amazonaws.com/eewk3il9okodib.png"
                alt="undefined"
              />

            </div>

            <div id="iwg4f" className="">

              <a
                target="_blank"
                className=""
                id="irgjju"
                href="https://www.instagram.com/spottytaghn/"
              >

                <img
                  className=""
                  id="iqcul1"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hafi3.png"
                  alt="undefined"
                />

                <p className="" id="iqq4mu">
                  <span>@spottytaghn</span>
                </p>

              </a>

              <a
                target="_blank"
                className=""
                id="i5vgyt"
                href="https://www.facebook.com/spottytaghn"
              >

                <img
                  className=""
                  id="iae27s"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3he6f6.png"
                  alt="undefined"
                />

                <p className="" id="ikdl4l">
                  <span>spottytaghn</span>
                </p>

              </a>

              <a
                target=""
                className=""
                id="ii2lsw"
                href="https://api.whatsapp.com/send?phone=50433027674"
              >

                <img
                  className=""
                  id="iu0rp5"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hfpjb.png"
                  alt="undefined"
                />

                <p className="" id="iqzql2">
                  <span>+504 3302-7674</span>
                </p>

              </a>

              <a
                target=""
                className=""
                id="i0mmmx"
                href="mailto:customerservice@spottytag.com"
              >

                <img
                  className=""
                  id="ih8ro2"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hg2uk.png"
                  alt="undefined"
                />

                <p className="" id="ivdrgi">
                  <span>customerservice@spottytag.com</span>
                </p>

              </a>

            </div>

          </div>

        </div>

      </div>

      <div id="ig891" className="">

        <p className="" id="i7l1k">
          <span>©2023 | spotty tag hn | privacy policies | terms of use</span>
          <div id="" className="">

          </div>

        </p>

      </div>

    </div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
  addLoftyTokenApp: (data) => dispatch(addLoftyToken(data))
});


export default connect(mapStateToProps, mapDispatchToProps)(Presetpassword);