
import axios from '../config/axios';

export const url = '/api/image'


export const createimage = ({
  token,
	idItem,
  loftyFile
}) => new Promise((resolve, reject) => {
  if (token ) {
		const formData = new FormData();
		formData.append('loftyFile', loftyFile);
		formData.append('idItem', idItem)
    axios.post(`${url}//create/basic/621c06fe6bef5b00160bf4c4`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',

        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch(error => reject(error));
  } else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});
      
export const getAllimage = ({
  token,
}) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}//list/basic/621c06fe6bef5b00160bf4c5`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});
      
export const deleteimage = ({
  token,
  _id,
}) => new Promise((resolve, reject) => {
  if (token && _id) {
    axios.delete(`${url}//delete/basic/621c06fe6bef5b00160bf4c6/${_id}`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!_id) {
    reject({
      status: 'error',
      info: 'Identificador no definido',
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
})
      

