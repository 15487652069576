
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getAllproducts } from '../../api/products.api';
import LoftyEditorJsReadOnly from '../../components/LoftyEditorJsReadOnly';
import LoftyEditorJs from '../../components/LoftyEditorJs';

import { addLoftyShoppingCart } from '../../actions/shoppingcart.actions';
import Alert from '../../components/Alert';

import './cartpage.scss';


// Hamburger
function loftyInitHam() {
  try {
    // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}





const Pcartpage = ({ match, loftytoken, loftyuser, addLoftyUserApp, deleteLoftytokenApp, addLoftyTokenApp, addLoftyShoppingCartApp, loftyShoppingCart, store }) => {

  const [products, setProducts] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const handleData = async () => {
      try {
        const res = await getAllproducts({
          token: 'asdsadsa'
        });
        const newProducts = [];
        res.data?.forEach((product) => {
          const exists = loftyShoppingCart.find((item) => item === product._id);
          if (product.status === 'public' && exists) {
            newProducts.push(product);
          }
        });
        let subtotalproducts = 0;
        let disccountProduct = 0;
        console.log(products);
        newProducts.forEach((product) => {
          if (store) {
            if (!store.tiendaspottytag5ol9r) {
              if (!product.freestore) {
                disccountProduct += (product.normalprice - product.disccountprice);
              } else if (product.freestore && newProducts.length === 1) {
                disccountProduct += (product.normalprice - product.disccountprice);
              } else if (product.freestore && newProducts.length > 1) {
                disccountProduct += (product.normalprice);
              }
            } else {
              if (!product.freestore) {
                disccountProduct += (product.normalprice - product.precioespecialtiendapropiazerj1);
              } else if (product.freestore && newProducts.length === 1) {
                disccountProduct += (product.normalprice - product.precioespecialtiendapropiazerj1);
              } else if (product.freestore && newProducts.length > 1) {
                disccountProduct += (product.normalprice);
              }
            }
          }
          subtotalproducts += product.normalprice;
        });
        const totalproduct = subtotalproducts - disccountProduct;
        setDiscount(disccountProduct)
        setSubtotal(subtotalproducts);
        setTotal(totalproduct);
        setProducts(newProducts);
      } catch (error) {
        console.log(error);
      }
    }
    handleData();
  }, [loftyShoppingCart])

  useEffect(() => {
    setTimeout(() => { loftyInitHam(); }, 2000);
  }, [])






  return (
    <div id="id6491bef6a52ebcacc7a80a89" >

      <div id="ivee" className="">

        <a
          target=""
          className=""
          id="i4tb6k"
          href="/"
        >

          <img
            className=""
            id="i1i8k"
            src="https://assetsprojects.s3.amazonaws.com/eewk3il9okodib.png"
            alt="undefined"
          />

        </a>

        <div id="" className="contentwrapperanimated ">

          <div id="" className="container_animated ">

            <label id="" className="menu__btn ">

              <span className="span_before " id="">

              </span>

              <span className="span_middle " id="">

              </span>

              <span className="span_after " id="">

              </span>

            </label>

            <label id="" className="menu__close__btn ">

              <span className="span__close__left " id="">

              </span>

              <span className="span__close__right " id="">

              </span>

            </label>

            <ul id="" className="menu__box ">

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/"
                  type=""
                >
                  <span>Home</span>
                </a>

              </li>

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/products"
                  type=""
                >
                  <span>Shop</span>
                </a>

              </li>

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/aboutus"
                  type=""
                >
                  <span>About Us</span>
                </a>

              </li>

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/cartpage"
                  type=""
                >
                  <span>Shopping Cart</span>
                </a>

              </li>

              <li className="linkshref " id="">

              </li>

            </ul>

          </div>

        </div>

        <div id="iq9l" className="">

          <a
            target=""
            className=""
            id="i5tql"
            href="/"
          >

            <div id="i1n85" className="">
              <span>Home</span>
            </div>

          </a>

          <a
            target=""
            className=""
            id="ifv1s"
            href="/products"
          >

            <div id="iqceg" className="">
              <span>Shop</span>
            </div>

          </a>

          <a
            target=""
            className=""
            id="ikkdy"
            href="/aboutus"
          >

            <div id="if57z" className="">
              <span>About Us</span>
            </div>

          </a>

          <a
            target=""
            className=""
            id="ixqzss"
            href="/blog"
          >

            <div id="i91zyl" className="">
              <span>Blog</span>
            </div>

          </a>

          <form id="islmj" className="" onSubmit={(e) => {
            e.preventDefault();
            const doc = {
              q: e.target.search.value,
            }
            const query = new URLSearchParams(doc);
            window.location.href = `/products?${query.toString()}`
          }}>

            <div id="iwr3jd" className="">

              <input
                name=""
                type="text"
                placeholder="Search"
                className=""
                id="ib4ir6"
              />

            </div>

            <button
              type=""
              name=""
              id="iyknx"
              className=""

            >

              <div id="ivb27" className="">
                <span>Search</span>
              </div>

            </button>

          </form>

          <div id="inqxd" className="">

            <a
              target=""
              className=""
              id="i6hs3"
              href="/cartpage"
            >

              <img
                className=""
                id="ijy2n"
                src="https://assetsprojects.s3.amazonaws.com/404p7nilj3fs1w7.png"
                alt="undefined"
              />

            </a>

          </div>

        </div>

      </div>

      <div id="ipezzh" className="">

      </div>

      <div id="ihis35" className="">

        <div id="ipdx1n" className="">

          <div id="i5y86f" className="">

            <div id="i6m88b" className="">

              <div id="isbh6f" className="">

              </div>

              <p className="" id="iyk15f">
                <span>Shopping Cart</span>
              </p>

            </div>

            <div id="inz8mi" className="">

              <div id="i4aw9k" className="">

                <p className="" id="iawxpf">
                  <span>Price</span>
                </p>

              </div>

              <div id="idm9i7" className="">

                <p className="" id="iqpqxd">
                  <span>Remove</span>
                </p>

              </div>

            </div>

          </div>

          {
            products?.map((product) => (
              <div key={product._id} id="ixbi3k" className="">

                <div id="idmahm" className="">

                  <div id="iosl8d" className="">

                  </div>

                  <div id="iemych" className="">

                    <img
                      className=""
                      id="ix5qqt"
                      src={product.loftyUrl}
                      alt={product.name}
                    />

                    <div id="ii4w0x" className="">

                      <p className="" id="igm9cy">
                        <span>
                          {product.name}
                        </span>
                      </p>

                    </div>

                  </div>

                </div>

                <div id="iz4oun" className="">

                  <div id="itn47q" className="">

                    <p className="" id="i28trb">
                      {
                        store ? (
                          <>
                            {
                              store.tiendaspottytag5ol9r ? (
                                <span>$ {`${(product.freestore && products.length > 1) ? '0.00' : product.precioespecialtiendapropiazerj1.toFixed(2)}`}</span>
                              ) : (
                                <span>$ {`${(product.freestore && products.length > 1) ? '0.00' : product.disccountprice.toFixed(2)}`}</span>
                              )
                            }
                          </>
                        ) : (
                          <span>$ {`${product.normalprice.toFixed(2)}`}</span>
                        )
                      }
                    </p>

                  </div>

                  <div id="iy5nkm" className="">

                    <button
                      type=""
                      name=""
                      id="iodyb9"
                      className="standard_button "
                      onClick={() => {
                        const newArray = loftyShoppingCart.map((item) => item);
                        const anotherArray = [];
                        newArray.forEach((item) => {
                          if (item !== product._id) {
                            anotherArray.push(item);
                          }
                        })
                        addLoftyShoppingCartApp(anotherArray);
                        alert('Deleted to shopping cart');
                      }}
                    >

                      <img
                        className=""
                        id="i9grup"
                        src="https://assetsprojects.s3.amazonaws.com/404p7nilj4maorf.png"
                        alt="undefined"
                      />

                    </button>

                  </div>

                </div>

              </div>
            ))
          }

        </div>

      </div>

      <div id="iv3hh7" className="">
        <Alert />
        <div id="imbuql" className="">

          <div id="i8algk" className="">

            <div className="" id="icqdvp">
              <span>Sub-Total:</span>
            </div>

            <div className="" id="iov9i1">
              <span>$
                {`${subtotal.toFixed(2)}`}
              </span>
            </div>

          </div>
          <div id="i8algk" className="">

            <div className="" id="icqdvp">
              <span>Store Discount:</span>
            </div>

            <div className="" id="iov9i1">
              <span>$
                {`${discount.toFixed(2)}`}
              </span>
            </div>

          </div>
          <div id="i8algk" className="">

            <div className="" id="icqdvp">
              <span>Total:</span>
            </div>

            <div className="" id="iov9i1">
              <span>$
                {`${total.toFixed(2)}`}
              </span>
            </div>

          </div>

          <div id="i23faf" className="">

            <a
              target=""
              className=""
              id="irszn1"
              href="/checkout"
            >

              <button
                type=""
                name=""
                id="if7mpn"
                className="standard_button "

              >

                <div id="ilpcni" className="">
                  <span>Proceed to checkout</span>
                </div>

                <p className="" id="itx2lq">

                </p>

              </button>

            </a>

            <a
              target=""
              className=""
              id="i7oa2s"
              href="/products"
            >

              <button
                type=""
                name=""
                id="iwio4x"
                className="standard_button "

              >

                <div id="i6wwlz" className="">
                  <span>
                    Continue shopping
                  </span>
                </div>

                <p className="" id="innb0d">

                </p>

              </button>

            </a>

          </div>

        </div>

      </div>

      <div id="ie6sa" className="">

        <div id="iqobg" className="">

          <p className="" id="ibllc">
            <span>Contact us for more information!</span>
            <br className="" id="" />

          </p>

          <div id="ihuk4" className="">

            <div id="iu87j" className="">

              <img
                className=""
                id="i67f"
                src="https://assetsprojects.s3.amazonaws.com/eewk3il9okodib.png"
                alt="undefined"
              />

            </div>

            <div id="iwg4f" className="">

              <a
                target="_blank"
                className=""
                id="irgjju"
                href="https://www.instagram.com/spottytaghn/"
              >

                <img
                  className=""
                  id="iqcul1"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hafi3.png"
                  alt="undefined"
                />

                <p className="" id="iqq4mu">
                  <span>@spottytaghn</span>
                </p>

              </a>

              <a
                target="_blank"
                className=""
                id="i5vgyt"
                href="https://www.facebook.com/spottytaghn"
              >

                <img
                  className=""
                  id="iae27s"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3he6f6.png"
                  alt="undefined"
                />

                <p className="" id="ikdl4l">
                  <span>spottytaghn</span>
                </p>

              </a>

              <a
                target=""
                className=""
                id="ii2lsw"
                href="https://api.whatsapp.com/send?phone=50433027674"
              >

                <img
                  className=""
                  id="iu0rp5"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hfpjb.png"
                  alt="undefined"
                />

                <p className="" id="iqzql2">
                  <span>+504 3302-7674</span>
                </p>

              </a>

              <a
                target=""
                className=""
                id="i0mmmx"
                href="mailto:customerservice@spottytag.com"
              >

                <img
                  className=""
                  id="ih8ro2"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hg2uk.png"
                  alt="undefined"
                />

                <p className="" id="ivdrgi">
                  <span>customerservice@spottytag.com</span>
                </p>

              </a>

            </div>

          </div>

        </div>

      </div>

      <div id="ig891" className="">

        <p className="" id="i7l1k">
          <span>©2023 | spotty tag hn | privacy policies | terms of use</span>
          <br className="" id="" />

        </p>

      </div>

    </div>
  );
}

const mapStateToProps = (state) => ({ ...state.shoppingcart, ...state.store });

const mapDispatchToProps = (dispatch) => ({
  addLoftyShoppingCartApp: (loftyShoppingCart) => dispatch(addLoftyShoppingCart({ loftyShoppingCart }))
});

export default connect(mapStateToProps, mapDispatchToProps)(Pcartpage);
