
import axios from '../config/axios';

export const url = '/api/orden'


export const getOneorden = ({
  token,
  _id,
}) => new Promise((resolve, reject) => {
  if (token && _id) {
    axios.get(`${url}//one/basic/64da0e78e0d023d6ff6ccf7d/${_id}`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!_id) {
    reject({
      status: 'error',
      info: 'Identificador no definido'
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});
      
export const createorden = ({
  token,
	email,
	nameclient,
	country,
	city,
	address,
	telephone,
	status,
	subtotal,
	discount,
	total,
	costdelivery,
	idstore,
	idcountry,
	idstate,

}) => new Promise((resolve, reject) => {
  if (token && email && nameclient && address && telephone && subtotal && discount && total && costdelivery && idcountry && idstate ) {

    axios.post(`${url}//create/basic/64da0e84e0d023d6ff6ccf8a`, {
		email,
		nameclient,
		country,
		city,
		address,
		telephone,
		status,
		subtotal,
		discount,
		total,
		costdelivery,
		idstore,
		idcountry,
		idstate,

    }, {
      headers: {
        
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch(error => reject(error));
  } else if (!email) {
		reject({
			status: 'error',
			info: 'email no esta definido',
		});
	}  else if (!nameclient) {
		reject({
			status: 'error',
			info: 'nameclient no esta definido',
		});
	}  else if (!address) {
		reject({
			status: 'error',
			info: 'address no esta definido',
		});
	}  else if (!telephone) {
		reject({
			status: 'error',
			info: 'telephone no esta definido',
		});
	}  else if (!subtotal) {
		reject({
			status: 'error',
			info: 'subtotal no esta definido',
		});
	}  else if (!discount) {
		reject({
			status: 'error',
			info: 'discount no esta definido',
		});
	}  else if (!total) {
		reject({
			status: 'error',
			info: 'total no esta definido',
		});
	}  else if (!costdelivery) {
		reject({
			status: 'error',
			info: 'costdelivery no esta definido',
		});
	}  else if (!idcountry) {
		reject({
			status: 'error',
			info: 'idcountry no esta definido',
		});
	}  else if (!idstate) {
		reject({
			status: 'error',
			info: 'idstate no esta definido',
		});
	}  else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});
      

