
import React, { useState, useEffect } from 'react';
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { connect } from 'react-redux';
import { getAllproducts } from '../../api/products.api';


import './checkoutpage.scss';
import { addLoftyShoppingCart } from '../../actions/shoppingcart.actions';
import { createLogPaypalordenLofty } from '../../api/logpaypalordenlofty.api';
import { getAllcountry } from '../../api/country.api';
import { getAllstate } from '../../api/state.api';
import { createLogStripelordenLofty } from '../../api/logstripeordenlofty.api';
import Alert from '../../components/Alert';

const stripePromise = loadStripe("pk_live_51MxzXMCwgsFQXeaF2BvwtmLwk00lLAT9MxrPiHMhkhcGka5ZxzDZIACriTON1WOBONUsKbU2vJ7M1qh5JPtRlZSb00CsTB3Y0v", {
  locale: 'en'
});

// Hamburger
function loftyInitHam() {
  try {
    // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}

const CheckoutForm = ({
  email, country, state, address, zipCode, petName, phone, checkDelivery,
  city, disccount, loftyShoppingCart, subtotal, total, store, products,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);

  return (
    <form
      className="card card-body"
      onSubmit={async (e) => {
        e.preventDefault();

        console.log(products);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement),
        });
        setLoading(true);

        if (!error) {
          const { id } = paymentMethod;
          try {
            
            if (email && country && state && address && zipCode && petName && phone) {
              const response = await createLogStripelordenLofty({
                address, city, costdelivery: checkDelivery ? COST_DELIVERY : 0, country,
                discount: disccount, email, nameclient: petName,
                shoppingCart: loftyShoppingCart, subtotal, telephone: phone,
                total, idstore: store?._id, idstate: state, idcountry: country,
                idStripeLofty: id, products
              });
              elements.getElement(CardElement).clear();
              alert('Payment was made successfully');
              window.location.href = `/order/${response.data.databaseId}`;
            } else if (!email) {
              alert('Email is empty');
            } else if (!city) {
              alert('City is empty');
            } else if (!country) {
              alert('Country is empty');
            } else if (!state) {
              alert('State is empty');
            } else if (!address) {
              alert('Address is empty');
            } else if (!zipCode) {
              alert('Zip code is empty');
            } else if (!petName) {
              alert("Pet's name us empty");
            } else {
              alert('Phone is empty');
            }
          } catch (error) {
            window.location.href = `/errorpayment`;
          }
        } else {
          alert('Error invalid credit card');
        }
        setLoading(false);
      }}
    >
      <CardElement />
      <div className='text-center mt-3'>
        <button  disabled={!stripe} type='submit' className='paypal_button m-auto'>
          {
            loading ? 'Loading ...' : 'Pay with Credit Card'
          }
        </button>
      </div>
    </form>
  );
};


const COST_DELIVERY = 8.00;

const Pcheckoutpage = ({ match, loftytoken, loftyuser, store, loftyShoppingCart }) => {



  const [products, setProducts] = useState([]);
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [petName, setPetName] = useState('');
  const [phone, setPhone] = useState('');
  const [checkDelivery, setCheckDelivery] = useState(false);
  const [listCountry, setListCountry] = useState([]);
  const [listState, setListState] = useState([]);
  const [loadingPaypal, setLoadingPaypal] = useState(false);

  // ordersumary
  const [subtotal, setSubtotal] = useState(0);
  const [disccount, setDisccount] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const handleData = async () => {
      try {
        const res = await getAllproducts({
          token: 'asdsadsa'
        });
        const newProducts = [];
        const storeProducts = [];
        res.data?.forEach((product) => {
          const exists = loftyShoppingCart.find((item) => item === product._id);
          if (product.status === 'public' && exists) {
            newProducts.push({
              url: product.loftyUrl,
              _id: product._id,
              petName: '',
              telephone: '',
              needinfo: product.needinfo
            });
            storeProducts.push(product);
          }
        });
        setProducts(newProducts);
        let subtotalproducts = 0;
        let disccountProduct = 0;
        storeProducts.forEach((product) => {
          if (store) {
            if (!store.tiendaspottytag5ol9r) {
              if (!product.freestore) {
                disccountProduct += (product.normalprice - product.disccountprice);
              } else if (product.freestore && newProducts.length === 1) {
                disccountProduct += (product.normalprice - product.disccountprice);
              } else if (product.freestore && newProducts.length > 1) {
                disccountProduct += (product.normalprice);
              }
            } else {
              if (!product.freestore) {
                disccountProduct += (product.normalprice - product.precioespecialtiendapropiazerj1);
              } else if (product.freestore && newProducts.length === 1) {
                disccountProduct += (product.normalprice - product.precioespecialtiendapropiazerj1);
              } else if (product.freestore && newProducts.length > 1) {
                disccountProduct += (product.normalprice);
              }
            }
          }
          subtotalproducts += product.normalprice;
        });
        const totalproduct = subtotalproducts - disccountProduct;
        // cargando los paises y departamentos
        setSubtotal(subtotalproducts);
        setDisccount(disccountProduct);
        setTotal(totalproduct);
        const resCountry = await getAllcountry({ token: 'sdas' });
        const resState = await getAllstate({ token: 'asaa' });
        setListCountry(resCountry.data);
        setListState(resState.data);
      } catch (error) {
        console.log(error);
      }
    }
    handleData();
  }, [loftyShoppingCart])

  useEffect(() => {
    setTimeout(() => { loftyInitHam(); }, 2000);
  }, [])

  return (
    <div id="id6492527ba52ebcacc7a80fa1" >

      <div id="ivee" className="">

        <a
          target=""
          className=""
          id="imjdjr"
          href="/"
        >

          <img
            className=""
            id="i1i8k"
            src="https://assetsprojects.s3.amazonaws.com/eewk3il9okodib.png"
            alt="undefined"
          />

        </a>

        <a
          target=""
          className="shopping-container-mobile"
          id="i6hs3"
          href="/cartpage"
        >

          <img
            className=""
            id="ijy2n"
            src="https://assetsprojects.s3.amazonaws.com/406racilqpyblxz.png"
            alt="undefined"
          />

        </a>

        <div id="" className="contentwrapperanimated ">

          <div id="" className="container_animated ">

            <label id="" className="menu__btn ">

              <span className="span_before " id="">

              </span>

              <span className="span_middle " id="">

              </span>

              <span className="span_after " id="">

              </span>

            </label>

            <label id="" className="menu__close__btn ">

              <span className="span__close__left " id="">

              </span>

              <span className="span__close__right " id="">

              </span>

            </label>

            <ul id="" className="menu__box ">

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="#"
                  type=""
                >
                  <span>Home</span>
                </a>

              </li>

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="#"
                  type=""
                >
                  <span>About</span>
                </a>

              </li>

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="#"
                  type=""
                >
                  <span>Team</span>
                </a>

              </li>

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="#"
                  type=""
                >
                  <span>Contact</span>
                </a>

              </li>

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="#"
                  type=""
                >
                  <span>Twitter</span>
                </a>

              </li>

            </ul>

          </div>

        </div>

        <div id="iq9l" className="">

          <a
            target=""
            className=""
            id="i5tql"
            href="/"
          >

            <div id="i1n85" className="">
              <span>Home</span>
            </div>

          </a>

          <a
            target=""
            className=""
            id="ifv1s"
            href="/products"
          >

            <div id="iqceg" className="">
              <span>Shop</span>
            </div>

          </a>

          <a
            target=""
            className=""
            id="ikkdy"
            href="/aboutus"
          >

            <div id="if57z" className="">
              <span>About Us</span>
            </div>

          </a>

          <a
            target=""
            className=""
            id="ixqzss"
            href="/blog"
          >

            <div id="i91zyl" className="">
              <span>Blog</span>
            </div>

          </a>

          <form
            id="islmj"
            className=""
            onSubmit={(e) => {
              e.preventDefault();
              const doc = {
                q: e.target.search.value,
              }
              const query = new URLSearchParams(doc);
              window.location.href = `/products?${query.toString()}`
            }}
          >

            <div id="irz9ej" className="">

              <input
                name="search"
                className=""
                id="i42p08"
              />

            </div>

            <button
              type="submit"
              name=""
              id="irfkh"
              className=""

            >

              <div id="ivb27" className="">
                <span>Search</span>
              </div>

            </button>

          </form>

          <div id="inqxd" className="">

            <a
              target=""
              className=""
              id="i6hs3"
              href="/cartpage"
            >

              <img
                className=""
                id="ijy2n"
                src="https://assetsprojects.s3.amazonaws.com/406racilqpyblxz.png"
                alt="undefined"
              />

            </a>

          </div>

        </div>

      </div>

      <div id="ipezzh" className="">

      </div>

      <div id="ihis35" className="">

        <div id="ipdx1n" className="">

          <div id="iwkkz" className="">

            <div id="itqo46" className="">

              <p className="" id="i87kc">
                <span>DELIVERY ADDRESS</span>
              </p>

              <div id="" className="formContainer ">

                <div
                  id="i261x"
                  className=""

                >

                  <div id="" className="title ">

                    <i className="fas fa-pencil-alt " id="">

                    </i>

                  </div>

                  <div id="" className="fieldsContainer ">

                    <div id="ihgrx" className="">

                      <div id="i9ophm" className="">

                        <div id="iqhzx" className="">
                          <span>Country</span>
                        </div>

                        <select
                          name=""
                          className="componentInput "
                          id=""
                          onChange={e => {
                            setCountry(e.target.value);
                          }}
                          value={country}
                        >
                          <option value=''>
                            {'Select one'}
                          </option>
                          ))
                          {
                            listCountry.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.name}
                              </option>
                            ))
                          }
                        </select>

                      </div>

                      <div id="i64jah" className="">

                        <div id="i1abtw" className="">
                          <span>State</span>
                        </div>

                        <select
                          name=""
                          className="componentInput "
                          id=""
                          onChange={e => {
                            setState(e.target.value);
                          }}
                          value={state}
                        >
                          <option value=''>
                            {'Select one'}
                          </option>
                          {
                            listState.filter(item => item.idCountry === country).map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.name}
                              </option>
                            ))
                          }
                        </select>

                      </div>

                    </div>

                    <div id="ii2bzq" className="">

                      <div id="ijl2w3" className="">

                        <div id="iyge5i" className="">
                          <span>City</span>
                        </div>

                        <input
                          name=""
                          className="componentInput "
                          id=""
                          value={city}
                          onChange={(e) => {
                            setCity(e.target.value);
                          }}
                        />

                      </div>

                      <div id="i9u9ro" className="">

                        <div id="iso3hk" className="">
                          <span>Address</span>
                        </div>

                        <input
                          name=""
                          className="componentInput "
                          id=""
                          value={address}
                          onChange={e => {
                            setAddress(e.target.value);
                          }}
                        />

                      </div>

                    </div>

                    <div id="i4xbrj" className="">

                      <div id="in8gvh" className="">

                        <div id="i9nibi" className="">
                          <span>Zip Code</span>
                        </div>

                        <input
                          name=""
                          className="componentInput "
                          id=""
                          value={zipCode}
                          onChange={e => {
                            setZipCode(e.target.value);
                          }}
                        />

                      </div>

                      <div id="i0alnf" className="">

                        <div id="i2m4cl" className="">
                          <span>Email</span>
                        </div>

                        <input
                          name=""
                          className="componentInput "
                          id=""
                          value={email}
                          onChange={e => {
                            setEmail(e.target.value);
                          }}
                        />

                      </div>

                    </div>
                    <div id="i4xbrj" className="">

                      <div id="in8gvh" className="">

                        <div id="i9nibi" className="">
                          <span>Client Name</span>
                        </div>

                        <input
                          name=""
                          className="componentInput "
                          id=""
                          value={petName}
                          onChange={e => {
                            setPetName(e.target.value);
                          }}
                        />

                      </div>

                      <div id="i0alnf" className="">

                        <div id="i2m4cl" className="">
                          <span>Telephone</span>
                        </div>

                        <input
                          name=""
                          className="componentInput "
                          id=""
                          value={phone}
                          onChange={e => {
                            setPhone(e.target.value);
                          }}
                        />

                      </div>

                    </div>

                    <div id="ifljm2" className="">

                      <div id="i56g2a" className="">

                        <div id="" className="checkboxMain ">

                          <label id="" className="checkboxContainer ">

                            <div id="ii1eoh" className="">
                              <span>Premium delivery</span>
                            </div>

                            <input
                              name=""
                              type="checkbox"
                              className="formCheckbox "
                              id=""
                              onChange={e => {
                                const info = e.target.checked;
                                setCheckDelivery(info);
                                if (info) {
                                  const totalLocal = (subtotal + COST_DELIVERY) - disccount;
                                  setTotal(totalLocal);
                                } else {
                                  const totalLocal = (subtotal) - disccount;
                                  setTotal(totalLocal);
                                }
                              }}
                            />

                            <span className="checkmark " id="">

                            </span>

                          </label>

                        </div>

                      </div>

                    </div>

                  </div>

                </div>

              </div>

            </div>

            <div id="i0k2rf" className="">

              <p className="" id="i4laia">
                <span>PLATE INFORMATION</span>
              </p>

              <div id="" className="formContainer ">

                {
                  products.filter((item) => item.needinfo === 'yes').map((product) => (
                    <div
                      id="itmg5q"
                      className=""
                      key={product._id}
                    >

                      <div id="" className="title ">

                        <i className="fas fa-pencil-alt " id="">

                        </i>

                      </div>

                      
                      <div id="" className="fieldsContainer ">

                        <div id="ingxgi" className="">

                        {
                          product.needinfo === 'yes' && (
                            <div id="iwagqc" className="">

                              <div id="ibucm6" className="">
                                <span>pet's name</span>
                              </div>

                              <input
                                name=""
                                className="componentInput "
                                id=""
                                value={product.petName}
                                maxLength={10}
                                onChange={e => {
                                  products.forEach((product2) => {
                                    if (product._id === product2._id) {
                                      product.petName = e.target.value.toUpperCase();
                                    }
                                  })
                                  setProducts(products.map((product2) => product2));
                                }}
                              />

                            </div>
                          )
                        }

                        </div>

                        {
                          product.needinfo === 'yes' && (
                            <div id="iojguy" className="">

                              <div id="iwmjbi" className="">

                                <div id="i7pu76" className="">
                                  <span>Phone</span>
                                </div>

                                <input
                                  name=""
                                  className="componentInput "
                                  maxLength={17}
                                  onChange={e => {
                                    products.forEach((product2) => {
                                      if (product._id === product2._id) {
                                        product.telephone = e.target.value;
                                      }
                                    })
                                    setProducts(products.map((product2) => product2));
                                  }}
                                  id=""
                                  value={product.telephone}
                                />

                              </div>

                            </div>
                          )
                        }

                      </div>

                      <div id="i0l6ep" className="">
                        <p className="" id="i4laia">
                          <span>
                            Sample
                          </span>
                        </p>
                        {
                          product.needinfo === 'yes' ? (
                            <div style={{position: 'relative', width: 300, color: 'white'}}>
                              <img
                                className=""
                                id="ia81uk"
                                src={product.url}
                                alt="undefined"
                              />
                              {
                                product.needinfo === 'yes' ? (
                                  <div style={{position: 'absolute', top: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <span style={{fontSize: 20}} >
                                      {product.petName}
                                    </span>
                                  </div>
                                ) : <></>
                              }
                            </div>
                          ) : <></>
                        }


                        <div style={{position: 'relative', width: 300, color: 'white'}}>
                          <img
                            className=""
                            id="i3f3ph"
                            src={product.url}
                            alt="undefined"
                          />
                          {
                            product.needinfo === 'yes' ? (
                              <div style={{position: 'absolute', top: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <span style={{fontSize: 20}} >
                                  {product.telephone}
                                </span>
                              </div>
                            ) : <></>
                          }
                        </div>

                      </div>

                    </div>
                  ))
                }
                
                {/* Los gratuitos */}
                {
                  products.filter((item) => item.needinfo === 'no').map((product) => (
                    <div
                      id="itmg5q"
                      className=""
                      key={product._id}
                    >

                      <div id="" className="title ">

                        <i className="fas fa-pencil-alt " id="">

                        </i>

                      </div>

                      
                      <div id="" className="fieldsContainer ">

                        <div id="ingxgi" className="">

                        {
                          product.needinfo === 'yes' && (
                            <div id="iwagqc" className="">

                              <div id="ibucm6" className="">
                                <span>pet's name</span>
                              </div>

                              <input
                                name=""
                                className="componentInput "
                                id=""
                                value={product.petName}
                                maxLength={10}
                                onChange={e => {
                                  products.forEach((product2) => {
                                    if (product._id === product2._id) {
                                      product.petName = e.target.value.toUpperCase();
                                    }
                                  })
                                  setProducts(products.map((product2) => product2));
                                }}
                              />

                            </div>
                          )
                        }

                        </div>

                        {
                          product.needinfo === 'yes' && (
                            <div id="iojguy" className="">

                              <div id="iwmjbi" className="">

                                <div id="i7pu76" className="">
                                  <span>Phone</span>
                                </div>

                                <input
                                  name=""
                                  className="componentInput "
                                  maxLength={17}
                                  onChange={e => {
                                    products.forEach((product2) => {
                                      if (product._id === product2._id) {
                                        product.telephone = e.target.value;
                                      }
                                    })
                                    setProducts(products.map((product2) => product2));
                                  }}
                                  id=""
                                  value={product.telephone}
                                />

                              </div>

                            </div>
                          )
                        }

                      </div>

                      <div id="i0l6ep" className="">
                        <p className="" id="i4laia">
                          <span>
                            Sample
                          </span>
                        </p>
                        {
                          product.needinfo === 'yes' ? (
                            <div style={{position: 'relative', width: 300, color: 'white'}}>
                              <img
                                className=""
                                id="ia81uk"
                                src={product.url}
                                alt="undefined"
                              />
                              {
                                product.needinfo === 'yes' ? (
                                  <div style={{position: 'absolute', top: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <span style={{fontSize: 20}} >
                                      {product.petName}
                                    </span>
                                  </div>
                                ) : <></>
                              }
                            </div>
                          ) : <></>
                        }


                        <div style={{position: 'relative', width: 300, color: 'white'}}>
                          <img
                            className=""
                            id="i3f3ph"
                            src={product.url}
                            alt="undefined"
                          />
                          {
                            product.needinfo === 'yes' ? (
                              <div style={{position: 'absolute', top: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <span style={{fontSize: 20}} >
                                  {product.telephone}
                                </span>
                              </div>
                            ) : <></>
                          }
                        </div>

                      </div>

                    </div>
                  ))
                }
              </div>

            </div>

          </div>

          <div id="iud3s7" className="">

            <p className="" id="icco8t">
              <span>Order Summary</span>
            </p>

            <div id="" className="formContainer ">

              <div
                id="idie71"
                className=""

              >

                <div id="" className="title ">

                  <i className="fas fa-pencil-alt " id="">

                  </i>

                </div>

                <div id="" className="fieldsContainer ">

                  <div id="ig0q0j" className="">

                    <div id="iosfog" className="">

                      <p className="" id="iro57v">
                        <span>Subtotal:</span>
                      </p>

                      <p className="" id="ig3dpu">
                        <span>${`${subtotal.toFixed(2)}`}</span>
                      </p>

                    </div>

                    <div id="i5697l" className="">

                      <p className="" id="i7p9wf">
                        <span>Delivery:</span>
                      </p>

                      <p className="" id="ivqq0x">
                        <span>${`${checkDelivery ? COST_DELIVERY.toFixed(2) : '0.00'}`}</span>
                      </p>

                    </div>

                    <div id="ie1c0n" className="">

                      <p className="" id="itbho8">
                        <span>Store Discount:</span>
                      </p>

                      <p className="" id="i6maui">
                        <span>${`${disccount.toFixed(2)}`}</span>
                      </p>

                    </div>

                    <div id="i5me7j" className="">

                      <p className="" id="ifzmmq">
                        <span>Total:</span>
                      </p>

                      <p className="" id="icyaco">
                        <span>${`${total.toFixed(2)}`}</span>
                      </p>

                    </div>

                  </div>


                  {/* <div id="inhuay" className="">

                    <button
                      type='button'
                      id=""
                      className="paypal_button "
                      onClick={async () => {
                        setLoadingPaypal(true);
                        try {
                          if (email && country && state && address && zipCode && petName && phone) {
                            
                            const response = await createLogPaypalordenLofty({
                              address, city, costdelivery: checkDelivery ? COST_DELIVERY : 0, country,
                              discount: disccount, email, nameclient: petName,
                              shoppingCart: loftyShoppingCart, subtotal, telephone: phone,
                              total, idstore: store?._id, idstate: state, idcountry: country, products,
                            });
                            window.location.href = response.data.responsePaypal.links[1].href;
                          } else if (!email) {
                            alert('Email is empty');
                          } else if (!city) {
                            alert('City is empty');
                          } else if (!country) {
                            alert('Country is empty');
                          } else if (!state) {
                            alert('State is empty');
                          } else if (!address) {
                            alert('Address is empty');
                          } else if (!zipCode) {
                            alert('Zip code is empty');
                          } else if (!petName) {
                            alert("Pet's name us empty");
                          } else {
                            alert('Phone is empty');
                          }
                        } catch (error) {
                          console.log(error);
                          alert('Error to process Paypal');
                        }
                        setLoadingPaypal(false);
                      }}
                      disabled={loadingPaypal}
                    >

                      <div id="i2zv8k" className="">
                        <span>Pay with:: </span>
                      </div>

                      <div id="idmrdf" className="">
                        <img src="https://editor.loftyapps.com/static/media/paypal.984e4b14da5fcd9660f826690bd23642.svg" alt="Paypal" />
                      </div>

                    </button>

                  </div> */}

                  <div id="i83nbl" className="">

                    <p style={{display: 'flex', justifyContent: 'space-around'}} id="i8p5dy">
                      <span>PAY WITH</span>
                      <img
                        style={{
                          width: 75,
                          borderRadius: 10
                        }}
                        alt='Stripe'
                        src='/stripe.png'
                      />
                    </p>

                    <div id="i170zs" className="">
                      {/* Aqui esta la forma de pago */}
                      <Elements stripe={stripePromise}>
                        <CheckoutForm
                          address={address}
                          checkDelivery={checkDelivery}
                          city={city}
                          country={country}
                          disccount={disccount}
                          email={email}
                          loftyShoppingCart={loftyShoppingCart}
                          petName={petName}
                          phone={phone}
                          state={state}
                          store={store}
                          subtotal={subtotal}
                          total={total}
                          zipCode={zipCode}
                          products={products}
                        />
                      </Elements>
                    </div>

                  </div>

                  <Alert />

                </div>

              </div>

            </div>

          </div>

        </div>

        <p className="" id="i0ddst">
          <span>By placing your order, you agree to Spotty Tag </span>
          <a
            target=""
            className=""
            id="i726fk"
            href="/policies"
            type=""
          >
            <span>privacy policy</span>
          </a>
          <span> and </span>
          <a
            target=""
            className=""
            id="ixqksh"
            href="/terms"
            type=""
          >
            <span>terms of use</span>
          </a>

        </p>

      </div>

      <div id="ie6sa" className="">

        <div id="iqobg" className="">

          <p className="" id="ibllc">
            <span>Contact us for more information!</span>
            <br className="" id="" />

          </p>

          <div id="ihuk4" className="">

            <div id="iu87j" className="">

              <a href='/'>
                <img
                  className=""
                  id="i67f"
                  src="https://assetsprojects.s3.amazonaws.com/eewk3il9okodib.png"
                  alt="undefined"
                />
              </a>

            </div>

            <div id="iwg4f" className="">

              <a
                target="_blank"
                className=""
                id="irgjju"
                href="https://www.instagram.com/spottytaghn/"
              >

                <img
                  className=""
                  id="iqcul1"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hafi3.png"
                  alt="undefined"
                />

                <p className="" id="iqq4mu">
                  <span>@spottytaghn</span>
                </p>

              </a>

              <a
                target="_blank"
                className=""
                id="i5vgyt"
                href="https://www.facebook.com/spottytaghn"
              >

                <img
                  className=""
                  id="iae27s"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3he6f6.png"
                  alt="undefined"
                />

                <p className="" id="ikdl4l">
                  <span>spottytaghn</span>
                </p>

              </a>

              <a
                target=""
                className=""
                id="ii2lsw"
                href="https://api.whatsapp.com/send?phone=50433027674"
              >

                <img
                  className=""
                  id="iu0rp5"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hfpjb.png"
                  alt="undefined"
                />

                <p className="" id="iqzql2">
                  <span>+504 3302-7674</span>
                </p>

              </a>

              <a
                target=""
                className=""
                id="i0mmmx"
                href="mailto:customerservice@spottytag.com"
              >

                <img
                  className=""
                  id="ih8ro2"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hg2uk.png"
                  alt="undefined"
                />

                <p className="" id="ivdrgi">
                  <span>customerservice@spottytag.com</span>
                </p>

              </a>

            </div>

          </div>

        </div>

      </div>

      <div id="ig891" className="">

        <p className="" id="i7l1k">
          <span>©2023 | spotty tag hn | privacy policies | terms of use</span>
          <br className="" id="" />

        </p>

      </div>

    </div>
  );
}

const mapStateToProps = (state) => ({ ...state.shoppingcart, ...state.store });

const mapDispatchToProps = (dispatch) => ({
  addLoftyShoppingCartApp: (loftyShoppingCart) => dispatch(addLoftyShoppingCart({ loftyShoppingCart }))
});



export default connect(mapStateToProps, mapDispatchToProps)(Pcheckoutpage);
