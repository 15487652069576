
import {ADD_PRODUCT_SHOPPING} from '../types/shoppingcart.types';

const initialState = {
  loftyShoppingCart: localStorage.getItem('loftyShoppingCart') ? JSON.parse(localStorage.getItem('loftyShoppingCart')) : [],
};

const reducerAuth = (state = initialState, {type, payload}) => {
  switch (type) {
    case ADD_PRODUCT_SHOPPING:
      return {
        ...state,
        loftyShoppingCart: payload?.loftyShoppingCart,
      };
    default:
      return state;
  }
};

export default reducerAuth;
