
import axios from '../config/axios';

export const url = '/api/reportFoundIt'

export const createreportFoundIt = ({
  token,
	userId,
	idItem,
	image,
	createdAt,
	details,
	address,
	contact,
	position,

}) => new Promise((resolve, reject) => {
  if (token && idItem && image && createdAt && address ) {

    axios.post(`${url}/create/basic/621cfd8ee78abe00162018c3`, {
		userId,
		idItem,
		image,
		createdAt,
		details,
		address,
		contact,
		position,

    }, {
      headers: {
        
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      console.log(data);
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch(error => {
      console.log('error ', error)
      reject(error);
    });
  } else if (!idItem) {
		reject({
			status: 'error',
			info: 'idItem no esta definido',
		});
	}  else if (!image) {
		reject({
			status: 'error',
			info: 'image no esta definido',
		});
	}  else if (!createdAt) {
		reject({
			status: 'error',
			info: 'createdAt no esta definido',
		});
	}  else if (!address) {
		reject({
			status: 'error',
			info: 'address no esta definido',
		});
	}  else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});

export const updatereportFoundIt = ({
  token,
  _id,
	userId,
	idItem,
	image,
	createdAt,
	details,
	address,
	contact,
	position,

}) => new Promise((resolve, reject) => {
  if (token && _id && idItem && image && createdAt && address ) {
    axios.post(`${url}/update/${_id}`, {
		userId,
		idItem,
		image,
		createdAt,
		details,
		address,
		contact,
		position,

    }, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error))
  } else if (!idItem) {
		reject({
			status: 'error',
			info: 'idItem no esta definido',
		});
	}  else if (!image) {
		reject({
			status: 'error',
			info: 'image no esta definido',
		});
	}  else if (!createdAt) {
		reject({
			status: 'error',
			info: 'createdAt no esta definido',
		});
	}  else if (!address) {
		reject({
			status: 'error',
			info: 'address no esta definido',
		});
	}  else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});

export const getAllreportFoundIt = ({
  token,
}) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}/list`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});

export const getOnereportFoundIt = ({
  token,
  _id,
}) => new Promise((resolve, reject) => {
  if (token && _id) {
    axios.get(`${url}/one/${_id}`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!_id) {
    reject({
      status: 'error',
      info: 'Identificador no definido'
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});

export const deletereportFoundIt = ({
  token,
  _id,
}) => new Promise((resolve, reject) => {
  if (token && _id) {
    axios.delete(`${url}/delete/${_id}`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!_id) {
    reject({
      status: 'error',
      info: 'Identificador no definido',
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
})

