
import axios from 'axios';

const url = 'https://idhunter.loftyapps.site';

const instancePro = axios.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default instancePro;

