
import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { getAllproducts } from '../../api/products.api';


import './products.scss';
import { addLoftyShoppingCart } from '../../actions/shoppingcart.actions';

// Hamburger
function loftyInitHam() {
  try {
    // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}





const Pproducts = ({ match, loftytoken, loftyuser, addLoftyUserApp, addLoftyShoppingCartApp, loftyShoppingCart, store }) => {



  const [products, setProducts] = useState([]);

  const search = new URLSearchParams(window.location.search);
  useEffect(() => {
    const handleData = async () => {
      try {
        const res = await getAllproducts({
          token: 'asdsadsa'
        });
        const newProducts = [];
        res.data?.forEach((product) => {
          if (!search.get('q')) {
            if (product.status === 'public' && product.needinfo === 'no') {
              newProducts.push(product);
            }
          } else {
            const regex = new RegExp(search.get('q'), 'i');
            if (product.status === 'public' && regex.test(product.name) && product.needinfo === 'no') {
              newProducts.push(product);
            }
          }
        });
        res.data?.forEach((product) => {
          if (!search.get('q')) {
            if (product.status === 'public' && product.needinfo === 'yes') {
              newProducts.push(product);
            }
          } else {
            const regex = new RegExp(search.get('q'), 'i');
            if (product.status === 'public' && regex.test(product.name) && product.needinfo === 'yes') {
              newProducts.push(product);
            }
          }
        });
        setProducts(newProducts);
      } catch (error) {
        console.log(error);
      }
    }
    handleData();
    setTimeout(() => {
      loftyInitHam();
    }, 2000);
  }, []);




  return (
    <div id="id64931323a52ebcacc7a81173" >
      <a
        target=""
        className=""
        id="iy5htn"
        href="/cartpage"
      >
        <span
          style={{
            color: 'white',
            position: 'absolute',
            top: -3,
            right: -5,
            width: 30,
            height: 30,
            backgroundColor: 'red',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {loftyShoppingCart.length}
        </span>
        <img
          className=""
          id="igw2te"
          src="https://assetsprojects.s3.amazonaws.com/eewk5ilxja7z7l.png"
          alt="undefined"
        />

      </a>
      <div id="ivee" className="">

        <img
          className=""
          id="i1i8k"
          src="https://assetsprojects.s3.amazonaws.com/eewk3il9okodib.png"
          alt="undefined"
        />

        <a
          target=""
          className="shopping-container-mobile"
          id="i6hs3"
          href="/cartpage"
        >

          <img
            className=""
            id="ijy2n"
            src="https://assetsprojects.s3.amazonaws.com/406racilqpyblxz.png"
            alt="undefined"
          />

        </a>

        <div id="" className="contentwrapperanimated ">

          <div id="" className="container_animated ">

            <label id="" className="menu__btn ">

              <span className="span_before " id="">

              </span>

              <span className="span_middle " id="">

              </span>

              <span className="span_after " id="">

              </span>

            </label>

            <label id="" className="menu__close__btn ">

              <span className="span__close__left " id="">

              </span>

              <span className="span__close__right " id="">

              </span>

            </label>

            <ul id="" className="menu__box ">

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/"
                  type=""
                >
                  <span>Home</span>
                </a>

              </li>

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/products"
                  type=""
                >
                  <span>About</span>
                </a>

              </li>

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/aboutus"
                  type=""
                >
                  <span>About Us</span>
                </a>

              </li>

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/cartpage"
                  type=""
                >
                  <span>Shopping Cart</span>
                </a>

              </li>

              <li className="linkshref " id="">

              </li>

            </ul>

          </div>

        </div>

        <div id="iq9l" className="">

          <a
            target=""
            className=""
            id="i5tql"
            href="/"
          >

            <div id="i1n85" className="">
              <span>Home</span>
            </div>

          </a>

          <a
            target=""
            className=""
            id="ifv1s"
            href="/products"
          >

            <div id="iqceg" className="">
              <span>Shop</span>
            </div>

          </a>

          <a
            target=""
            className=""
            id="ikkdy"
            href="/aboutus"
          >

            <div id="if57z" className="">
              <span>About Us</span>
            </div>

          </a>

          <a
            target=""
            className=""
            id="i1jyrj"
            href="/blog"
          >

            <div id="ijsj7y" className="">
              <span>Blog</span>
            </div>

          </a>

          <form
            id="islmj"
            className=""
            onSubmit={(e) => {
              e.preventDefault();
              const doc = {
                q: e.target.search.value,
              }
              const query = new URLSearchParams(doc);
              window.location.href = `/products?${query.toString()}`
            }}
          >

            <div id="izfgnl" className="">

              <input
                name="search"
                className=""
                id="izqgxj"
                defaultValue={search.get('q')}
              />

            </div>

            <button
              type="submit"
              name=""
              id="irtlk"
              className=""

            >

              <div id="ivb27" className="">
                <span>Search</span>
              </div>

            </button>

          </form>

          <div id="inqxd" className="">

            <a
              target=""
              className=""
              id="i6hs3"
              href="/cartpage"
            >

              <img
                className=""
                id="ijy2n"
                src="https://assetsprojects.s3.amazonaws.com/406racilqpyblxz.png"
                alt="undefined"
              />

            </a>

          </div>

        </div>

      </div>

      <form
        id="ipezzh"
        className=""
        onSubmit={(e) => {
          e.preventDefault();
          const doc = {
            q: e.target.search.value,
          }
          const query = new URLSearchParams(doc);
          window.location.href = `/products?${query.toString()}`
        }}
      >

        <div id="ioo0tk" className="">

          <input
            name="search"
            className="formInput "
            id=""
            defaultValue={search.get('q')}
          />

          <button
            type="submit"
            name=""
            id="ijfenn"
            className=""

          >

            <div id="iwowvw" className="">
              <span>Search</span>
            </div>

          </button>

        </div>

      </form>

      <div id="ihis35" className="">

        <div id="ipdx1n" className="">

          <div id="i5y86f" className="">

            <div id="inz8mi" className="">

              <div id="i5elgl" className="">

                <div id="i4aw9k" className="">

                  {
                    products?.map((product) => (
                      <div key={product._id} id="iy762j" className="">

                        <br />
                        <br />
                        <img
                          className=""
                          id="itq462"
                          src={product.loftyUrl}
                          alt={product.name}
                        />

                        <p className="" id="iw6wbh">
                          <span>{product.name}</span>
                        </p>

                        {
                          store ? (
                            <p className="" id="itxyqz">
                              <del>
                                <span>${`${product.normalprice.toFixed(2)}`}</span>
                                <div id="" className="">
                                </div>
                              </del>
                            </p>
                          ) : (
                            <p className="" id="itxyqz">
                              <span>${`${product.normalprice.toFixed(2)}`}</span>
                            </p>
                          )
                        }

                        {
                          store ? (
                            <>
                              {
                                store?.tiendaspottytag5ol9r ? (
                                  <>
                                    <p className="" id="itxyqz">
                                      <h2>${`${product.precioespecialtiendapropiazerj1.toFixed(2)}`}</h2>
                                      <div id="" className="">

                                      </div>

                                    </p>
                                  </>
                                ) : (
                                  <>
                                    <p className="" id="itxyqz">
                                      <h2>${`${product.disccountprice.toFixed(2)}`}</h2>
                                      <div id="" className="">

                                      </div>

                                    </p>
                                  </>
                                )
                              }
                            </>
                          ) : <></>
                        }


                        <button
                          type=""
                          name=""
                          id="ipteyk"
                          className="standard_button "
                          onClick={() => {
                            const newArray = loftyShoppingCart.map((item) => item);
                            // agregando el nuevo producto gratis por tienda
                            if (store && !product.freestore) {
                              products.forEach((item) => {
                                if (item.freestore) {
                                  newArray.push(item._id);
                                }
                              });
                            }
                            const exists = newArray.find((item2) => item2 === product._id);
                            if (!exists) {
                              newArray.push(product._id);
                            }
                            addLoftyShoppingCartApp(newArray);
                            alert('Added to shopping cart');
                          }}
                        >

                          <div id="im40qp" className="">
                            <span>Add to cart</span>
                          </div>

                        </button>

                      </div>
                    ))
                  }

                </div>

              </div>

            </div>

          </div>

        </div>

      </div>

      <div id="ifwt2y" className="">

        <div id="ifoaba" className="">

        </div>

        <div id="izaouf" className="">

          <p className="" id="is41yk">
            <span>1</span>
          </p>

        </div>

      </div>

      <div id="ie6sa" className="">

        <div id="iqobg" className="">

          <p className="" id="ibllc">
            <span>Contact us for more information!</span>
            <div id="" className="">

            </div>

          </p>

          <div id="ihuk4" className="">

            <div id="iu87j" className="">

              <img
                className=""
                id="i67f"
                src="https://assetsprojects.s3.amazonaws.com/eewk3il9okodib.png"
                alt="undefined"
              />

            </div>

            <div id="iwg4f" className="">

              <a
                target="_blank"
                className=""
                id="irgjju"
                href="https://www.instagram.com/spottytaghn/"
              >

                <img
                  className=""
                  id="iqcul1"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hafi3.png"
                  alt="undefined"
                />

                <p className="" id="iqq4mu">
                  <span>@spottytaghn</span>
                </p>

              </a>

              <a
                target="_blank"
                className=""
                id="i5vgyt"
                href="https://www.facebook.com/spottytaghn"
              >

                <img
                  className=""
                  id="iae27s"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3he6f6.png"
                  alt="undefined"
                />

                <p className="" id="ikdl4l">
                  <span>spottytaghn</span>
                </p>

              </a>

              <a
                target=""
                className=""
                id="ii2lsw"
                href="https://api.whatsapp.com/send?phone=50433027674"
              >

                <img
                  className=""
                  id="iu0rp5"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hfpjb.png"
                  alt="undefined"
                />

                <p className="" id="iqzql2">
                  <span>+504 3302-7674</span>
                </p>

              </a>

              <a
                target=""
                className=""
                id="i0mmmx"
                href="mailto:customerservice@spottytag.com"
              >

                <img
                  className=""
                  id="ih8ro2"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hg2uk.png"
                  alt="undefined"
                />

                <p className="" id="ivdrgi">
                  <span>customerservice@spottytag.com</span>
                </p>

              </a>

            </div>

          </div>

        </div>

      </div>

      <div id="ig891" className="">

        <p className="" id="i7l1k">
          <span>©2023 | spotty tag hn | privacy policies | terms of use</span>
          <div id="" className="">

          </div>

        </p>

      </div>

    </div>
  );
}

const mapStateToProps = (state) => ({ ...state.shoppingcart, ...state.store });

const mapDispatchToProps = (dispatch) => ({
  addLoftyShoppingCartApp: (loftyShoppingCart) => dispatch(addLoftyShoppingCart({ loftyShoppingCart }))
});

export default connect(mapStateToProps, mapDispatchToProps)(Pproducts);
