import React from 'react';

const Alert = () => (
  <div className="alert alert-primary" role="alert">
    SELECT SHIPPING: <br />
      1) free shipping is through conventional postal service, which would take between 7 to 10 work days; <br />
      2) Prime shipping, through postal service, which could be between 3 to 5 work days,  (if you select this way, an additional fee will be reflected when completing the order) <br />
      3) Online purchases and shipping only available for the UNITED STATES & CANADA
  </div>
);

export default Alert;