
import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import Compressor from 'compressorjs';

import { createreportFoundIt } from '../../api/reportFoundIt.api'
import { getOneitem } from '../../api/item.api';
import { getAllimage } from '../../api/image.api';
import { createnotifications } from '../../api/notifications.api';

import './reportform.scss';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      reject(error);
    };
  })
}

// Hamburger
function loftyInitHam() {
  try {
    // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}


function getPosition() {
  return new Promise((resolve, reject) => {
    window.navigator.geolocation.getCurrentPosition((position) => {
      resolve(position);
    }, (error) => {
      if (error.PERMISSION_DENIED) {
        resolve(undefined);
      } else {
        reject(error);
      }
    });
  })
}


const Preportform = ({ match, loftytoken, loftyuser, addLoftyUserApp }) => {

  const [doc, setDoc] = useState({})
  const [position, setPosition] = useState({});

  useEffect(() => {
    const loftyHandleData = async () => {
      try {
        let newDoc = {};
        const resItem = await getOneitem({ _id: match.params.id, token: 'asa' });
        newDoc = resItem.data;
        const listImages = [];
        const resImages = await getAllimage({ token: '1' });
        resImages.data.forEach(item => {
          if (item.idItem === newDoc._id) {
            listImages.push(item);
          }
        });
        newDoc.listImages = listImages;
        if (listImages.length > 0) {
          newDoc.urlImage = listImages[0].loftyUrl
        }
        setDoc(newDoc);
        getPosition().then((res) => {
          setPosition({
            latitude: res.coords.latitude,
            longitude: res.coords.longitude,
          });
        });
      } catch (error) {
        // code
      }
    }
    loftyHandleData();
  }, []);

  setTimeout(() => { loftyInitHam(); }, 2000);




  return (
    <div id="id64d2852092554645256462d3" >

      <div id="ivee" className="">

        <a
          target=""
          className=""
          id="i8yvab"
          href="/"
        >

          <img
            className=""
            id="i1i8k"
            src="https://assetsprojects.s3.amazonaws.com/eewk3il9okodib.png"
            alt="undefined"
          />

        </a>

        <a
          target=""
          className="shopping-container-mobile"
          id="i6hs3"
          href="/cartpage"
        >

          <img
            className=""
            id="ijy2n"
            src="https://assetsprojects.s3.amazonaws.com/406racilqpyblxz.png"
            alt="undefined"
          />

        </a>

        <div id="" className="contentwrapperanimated ">

          <div id="" className="container_animated ">

            <label id="" className="menu__btn ">

              <span className="span_before " id="">

              </span>

              <span className="span_middle " id="">

              </span>

              <span className="span_after " id="">

              </span>

            </label>

            <label id="" className="menu__close__btn ">

              <span className="span__close__left " id="">

              </span>

              <span className="span__close__right " id="">

              </span>

            </label>

            <ul id="" className="menu__box ">

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/"
                  type=""
                >
                  <span>Home</span>
                </a>

              </li>

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/products"
                  type=""
                >
                  <span>Shop</span>
                </a>

              </li>

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/aboutus"
                  type=""
                >
                  <span>About Us</span>
                </a>

              </li>

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/cartpage"
                  type=""
                >
                  <span>Shopping Cart</span>
                </a>

              </li>

              <li className="linkshref " id="">

              </li>

            </ul>

          </div>

        </div>

        <div id="iq9l" className="">

          <a
            target=""
            className=""
            id="i5tql"
            href="/"
          >

            <div id="i1n85" className="">
              <span>Home</span>
            </div>

          </a>

          <a
            target=""
            className=""
            id="ifv1s"
            href="/products"
          >

            <div id="iqceg" className="">
              <span>Shop</span>
            </div>

          </a>

          <a
            target=""
            className=""
            id="ikkdy"
            href="/aboutus"
          >

            <div id="if57z" className="">
              <span>About Us</span>
            </div>

          </a>

          <a
            target=""
            className=""
            id="i1jyrj"
            href="/blog"
          >

            <div id="ijsj7y" className="">
              <span>Blog</span>
            </div>

          </a>

          <form
            id="islmj"
            className=""
            onSubmit={(e) => {
              e.preventDefault();
              const doc = {
                q: e.target.search.value,
              }
              const query = new URLSearchParams(doc);
              window.location.href = `/products?${query.toString()}`
            }}
          >

            <div id="iafayo" className="">

              <input
                name="search"
                className=""
                id="i6q516"
              />

            </div>

            <button
              type="submit"
              name=""
              id="irtlk"
              className=""

            >

              <div id="ivb27" className="">
                <span>Search</span>
              </div>

            </button>

          </form>

          <div id="inqxd" className="">

            <a
              target=""
              className=""
              id="i6hs3"
              href="/cartpage"
            >

              <img
                className=""
                id="ijy2n"
                src="https://assetsprojects.s3.amazonaws.com/406racilqpyblxz.png"
                alt="undefined"
              />

            </a>

          </div>

        </div>

      </div>

      <div id="iigz33" className="">

        <div id="issu69" className="">

          <button
            type=""
            name=""
            id="ig6t8i"
            className=""

          >

            <div id="" className="">
              <span>Search</span>
            </div>

          </button>

        </div>

        <div id="i7kjyf" className="">

          <p className="" id="ij0j5r">
            <span>Reporte de Búsqueda</span>
          </p>

          <p className="" id="i9i7hn">
            <span>Enter the address where you found the pet and take a photo</span>
          </p>

        </div>

      </div>

      <div id="ihis35" className="">

        <div id="iw78x" className="">

          <div id="ipdx1n" className="">

            <div id="i4lrf" className="">

              <div id="ijetx2" className="">

              </div>

              <div id="i4aj1m" className="">

                {
                  doc.urlImage ? (
                    <img
                      className=""
                      id="i42bof"
                      src={doc.urlImage}
                      alt="undefined"
                    />
                  ) : (
                    <img
                      className=""
                      id="i42bof"
                      src="https://assetsprojects.s3.amazonaws.com/405wf6hl5h2qmoa.png"
                      alt="undefined"
                    />
                  )
                }
                
              </div>

              <div id="ikwmrv" className="">

                <p className="" id="iuzhxd">
                  <span>
                    {doc.description}
                  </span>
                  <div id="" className="">

                  </div>

                </p>

                <div id="i8subx" className="">

                  <div className="" id="ias3a9">

                    <form
                      id="iv7ey4"
                      className=""
                      onSubmit={async (e) => {
                        e.preventDefault();
                        try {
                          const loftyimage = e.target.image.files[0];
                          new Compressor(loftyimage, {
                            quality: 0.4,
                            success: async (file) => {
                              try {
                                const file_image = await getBase64(file);
                                const res = await createreportFoundIt({
                                  idItem: doc._id,
                                  image: file_image,
                                  createdAt: new Date(),
                                  details: e.target.details.value,
                                  address: e.target.address.value,
                                  position: (position.latitude && position.longitude) ? ({
                                    latitude: position.latitude,
                                    longitude: position.longitude,
                                  }) : undefined,
                                  token: 'asdasa'
                                });
                                console.log('muestra');
                                await createnotifications({
                                  titlespanish: 'Se encontró tu mascota',
                                  titleenglish: 'Your pet was found',
                                  descriptionspanish: `Alguién encontro a tu mascota`,
                                  descriptionenglish: `Somebody found your pet`,
                                  iditem: match.params.id,
                                  reportid: res.data._id,
                                  type: 'report',
                                  userid: doc.idUser,
                                  token: 'asda'
                                });
                                alert('Se enviaron los datos de manera exitosa');
                              } catch (error) {
                                console.log(error);
                                alert('Lo sentimos se produjo un error intente más tarde.')
                              }
                            }
                          });
                        } catch (error) {
                          console.log(error);
                          alert('Lo sentimos se produjo un error intente más tarde.')
                        }
                      }}

                    >

                      <div id="i4kcb1" className="">

                        <label id="ip6hhl" className="">
                          <span>Details</span>
                        </label>

                        <div id="" className="">

                        </div>

                        <textarea
                          style={{ padding: 10 }}
                          name="details"
                          className=""
                          id="i2jwwr"
                        />

                      </div>

                      <div id="i54yl1" className="">

                        <label id="iax1n6" className="">
                          <span>Address</span>
                        </label>

                        <div id="" className="">

                        </div>

                        <textarea
                          style={{ padding: 10 }}
                          name="address"
                          className=""
                          id="i0tzwh"
                        />

                      </div>

                      <div id="i54yl1" className="">

                        <label id="iax1n6" className="">
                          <span>Photo</span>
                        </label>

                        <div id="" className="">

                        </div>

                        <input
                          name="image"
                          className=""
                          type="file"
                          id="i8tfhj"
                        />

                      </div>

                      <br />

                      <div id="i5tpna" className="">

                        <button
                          type="submit"
                          name=""
                          id="icy7gf"
                          className=""

                        >
                          <span>Report</span>
                          <div id="" className="">

                          </div>

                        </button>

                      </div>

                    </form>

                  </div>

                </div>

              </div>

            </div>

          </div>

        </div>

      </div>

      <div id="ie6sa" className="">

        <div id="iqobg" className="">

          <p className="" id="ibllc">
            <span>Contact us for more information!</span>
            <div id="" className="">

            </div>

          </p>

          <div id="ihuk4" className="">

            <div id="iu87j" className="">

              <img
                className=""
                id="i67f"
                src="https://assetsprojects.s3.amazonaws.com/eewk3il9okodib.png"
                alt="undefined"
              />

            </div>

            <div id="iwg4f" className="">

              <a
                target="_blank"
                className=""
                id="irgjju"
                href="https://www.instagram.com/spottytaghn/" rel="noreferrer"
              >

                <img
                  className=""
                  id="iqcul1"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hafi3.png"
                  alt="undefined"
                />

                <p className="" id="iqq4mu">
                  <span>@spottytaghn</span>
                </p>

              </a>

              <a
                target="_blank"
                className=""
                id="i5vgyt"
                href="https://www.facebook.com/spottytaghn" rel="noreferrer"
              >

                <img
                  className=""
                  id="iae27s"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3he6f6.png"
                  alt="undefined"
                />

                <p className="" id="ikdl4l">
                  <span>spottytaghn</span>
                </p>

              </a>

              <a
                target=""
                className=""
                id="ii2lsw"
                href="https://api.whatsapp.com/send?phone=50433027674"
              >

                <img
                  className=""
                  id="iu0rp5"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hfpjb.png"
                  alt="undefined"
                />

                <p className="" id="iqzql2">
                  <span>+504 3302-7674</span>
                </p>

              </a>

              <a
                target=""
                className=""
                id="i0mmmx"
                href="mailto:customerservice@spottytag.com"
              >

                <img
                  className=""
                  id="ih8ro2"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hg2uk.png"
                  alt="undefined"
                />

                <p className="" id="ivdrgi">
                  <span>customerservice@spottytag.com</span>
                </p>

              </a>

            </div>

          </div>

        </div>

      </div>

      <div id="ig891" className="">

        <p className="" id="i7l1k">
          <span>©2023 | spotty tag hn | privacy policies | terms of use</span>
          <div id="" className="">

          </div>

        </p>

      </div>

    </div>
  );
}

const mapStateToProps = (state) => state.auth;



export default connect(mapStateToProps)(Preportform);
