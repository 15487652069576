
import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { getAllproducts } from '../../api/products.api';



import './pagenewhome.scss';
import { addLoftyShoppingCart } from '../../actions/shoppingcart.actions';


// Hamburger
function loftyInitHam() {
  try {
    // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}





const Ppagenewhome = ({ store, loftytoken, addLoftyShoppingCartApp, loftyShoppingCart }) => {

  const [products, setProducts] = useState([]);
  const [swiperProduct, setSwiperProduct] = useState();

  useEffect(() => {
    const handleData = async () => {
      try {
        const res = await getAllproducts({
          token: 'asdsadsa'
        });
        const newProducts = [];
        res.data?.forEach((product) => {
          if (product.status === 'public' && product.needinfo === 'no') {
            newProducts.push(product);
          }
        });
        res.data?.forEach((product) => {
          if (product.status === 'public' && product.needinfo === 'yes') {
            newProducts.push(product);
          }
        });
        setProducts(newProducts);
      } catch (error) {
        console.log(error);
      }
    }
    handleData();
    setTimeout(() => {
      loftyInitHam();
      // eslint-disable-next-line no-undef
      const swiper = new Swiper(".mySwiperproduct", {
        slidesPerView: "auto",
        grabCursor: true,
        spaceBetween: 30,
        freeMode: true,
      });
      setSwiperProduct(swiper);
    }, 2000);
  }, []);
  return (
    <div id="id6490d4afa52ebcacc7a80394" >
      <a
        target=""
        className=""
        id="i1y4w5"
        href="/cartpage"
      >
        <span
          style={{
            color: 'white',
            position: 'absolute',
            top: -3,
            right: -5,
            width: 30,
            height: 30,
            backgroundColor: 'red',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {loftyShoppingCart.length}
        </span>
        <img
          className=""
          id="i9h56k"
          src="https://assetsprojects.s3.amazonaws.com/eewk5ilxja7z7l.png"
          alt="undefined"
        />

      </a>
      <div id="ivee" className="">

        <a
          target=""
          className=""
          id="i1dgzw"
          href="/"
        >

          <img
            className=""
            id="i1i8k"
            src="https://assetsprojects.s3.amazonaws.com/eewk3il9okodib.png"
            alt="undefined"
          />

        </a>

        <a
          target=""
          className="shopping-container-mobile"
          id="i6hs3"
          href="/cartpage"
        >

          <img
            className=""
            id="ijy2n"
            src="https://assetsprojects.s3.amazonaws.com/406racilqpyblxz.png"
            alt="undefined"
          />

        </a>

        <div id="" className="contentwrapperanimated ">

          <div id="" className="container_animated ">

            <label id="" className="menu__btn ">

              <span className="span_before " id="">

              </span>

              <span className="span_middle " id="">

              </span>

              <span className="span_after " id="">

              </span>

            </label>

            <label id="" className="menu__close__btn ">

              <span className="span__close__left " id="">

              </span>

              <span className="span__close__right " id="">

              </span>

            </label>

            <ul id="" className="menu__box ">

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/"
                  type=""
                >
                  <span>Home</span>
                </a>

              </li>

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/products"
                  type=""
                >
                  <span>Shop</span>
                </a>

              </li>

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/aboutus"
                  type=""
                >
                  <span>About Us</span>
                </a>

              </li>

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/cartpage"
                  type=""
                >
                  <span>Shopping cart</span>
                </a>

              </li>

              <li className="linkshref " id="">

              </li>

            </ul>

          </div>

        </div>

        <div id="iq9l" className="">

          <a
            target=""
            className=""
            id="i5tql"
            href="/"
          >

            <div id="i1n85" className="">
              <span>Home</span>
            </div>

          </a>

          <a
            target=""
            className=""
            id="ifv1s"
            href="/products"
          >

            <div id="iqceg" className="">
              <span>Shop</span>
            </div>

          </a>

          <a
            target=""
            className=""
            id="ikkdy"
            href="/aboutus"
          >

            <div id="if57z" className="">
              <span>About Us</span>
            </div>

          </a>

          <a
            target=""
            className=""
            id="i48anl"
            href={`/blog`}
          >

            <div id="iivq6i" className="">
              <span>Blog</span>
            </div>

          </a>

          <form
            id="islmj"
            className=""
            onSubmit={(e) => {
              e.preventDefault();
              const doc = {
                q: e.target.search.value,
              }
              const query = new URLSearchParams(doc);
              window.location.href = `/products?${query.toString()}`
            }}
          >

            <div id="i8ln1r" className="">

              <input
                name="search"
                className=""
                id="iorknq"
              />

            </div>

            <button
              type="submit"
              name=""
              id="ihcii"
              className=""

            >

              <div id="ivb27" className="">
                <span>Search</span>
              </div>

            </button>

          </form>

          <div id="inqxd" className="">

            <a
              target=""
              className=""
              id="i6hs3"
              href="/cartpage"
            >

              <img
                className=""
                id="ijy2n"
                src="https://assetsprojects.s3.amazonaws.com/406racilqpyblxz.png"
                alt="undefined"
              />

            </a>

          </div>

        </div>

      </div>

      <div id="ipjgg" className="">

        <div id="" className="swiper mySwiper ">

          <div id="" className="swiper-wrapper ">

            <div id="itrply" className="swiper-slide ">

              <img
                className=""
                id="io6doc"
                src="https://assetsprojects.s3.amazonaws.com/eewk2ilvxuq6gb.png"
                alt="undefined"
              />

            </div>

            <div id="iipxxk" className="swiper-slide ">

              <img
                className=""
                id="i9w2dm"
                src="https://assetsprojects.s3.amazonaws.com/eewk2ilvxuqi9r.png"
                alt="undefined"
              />

            </div>

            <div id="iavs5h" className="swiper-slide ">

              <img
                className=""
                id="iqta9u"
                src="https://assetsprojects.s3.amazonaws.com/eewk2ilvxuqtbb.png"
                alt="undefined"
              />

            </div>

            <div id="i8pprf" className="swiper-slide ">

              <img
                className=""
                id="iwhr2a"
                src="https://assetsprojects.s3.amazonaws.com/eewk2ilvxur9lb.png"
                alt="undefined"
              />

            </div>

          </div>

          <div id="" className="swiper-button-next ">

          </div>

          <div id="" className="swiper-button-prev ">

          </div>

          <div id="" className="swiper-pagination ">

          </div>

        </div>

      </div>

      <div id="iu1vxq" className="">

        <div id="i9lqvz" className="">

          <div id="ie5nhm" className="">

            <div id="ie689e" className="">

              <img
                className=""
                id="if5yhs"
                src="https://assetsprojects.s3.amazonaws.com/404p7niljspl4a5.png"
                alt="undefined"
              />

            </div>

            <div id="i65p1t" className="">

              <p className="" id="i3eq1x">

                <span className="" id="i49z3v">

                  <b className="" id="">
                    <span>Solidarity Program</span>
                  </b>

                  <br className="" id="" />
                  <span>We support the WHITE CITY FOUNDATION in the "home & adoption program for abandoned pets" in Latin American Countries.</span>
                </span>

              </p>

            </div>

          </div>

        </div>

        <div id="isd1bb" className="">

          <div id="ili17q" className="">

            <div id="ix3xjx" className="">

              <img
                className=""
                id="i8mf21"
                src="https://assetsprojects.s3.amazonaws.com/404p7niljspq2a1.png"
                alt="undefined"
              />

            </div>

            <div id="i77i5m" className="">

              <p className="" id="iqocr9">

                <span className="" id="iumwdo">

                  <b className="" id="i9s8h">
                    <span>Quality Guarantee</span>
                  </b>

                  <br className="" id="" />
                  <span>All products are personalized providing unique quality.</span>
                </span>

              </p>

            </div>

          </div>

        </div>

        <div id="i3lfbj" className="">

          <div id="i33tx7" className="">

            <div id="ivdf7m" className="">

              <img
                className=""
                id="i4kthf"
                src="https://assetsprojects.s3.amazonaws.com/404p7niljspsb51.png"
                alt="undefined"
              />

            </div>

            <div id="ik8dha" className="">

              <p className="" id="i9ckii">

                <span className="" id="iuhn6d">

                  <b className="" id="">
                    <span>Pet Assistance</span>
                  </b>

                  <br className="" id="" />
                  <span>identifies and makes it possible for your pet to return home safely and promptly.</span>
                </span>

              </p>

            </div>

          </div>

        </div>

      </div>

      <div id="ibv7jz" className="">

        <div id="i8xtmi" className="">

          <div id="i366pl" className="">

            <p className="" id="iz8dge">
              <span>Our Products</span>
            </p>

          </div>

          <div id="i1raze" className="">

            <button
              type=""
              name=""
              id=""
              className="standard_button "
              onClick={() => {
                swiperProduct.slidePrev();
              }}
            >

              <div id="i3la21" className="">
                {`<-`}
              </div>

            </button>

            <button
              type=""
              name=""
              id=""
              className="standard_button "
              onClick={() => {
                swiperProduct.slideNext();
              }}
            >

              <div id="io90ci" className="">
                {`->`}
              </div>

            </button>

          </div>

        </div>

        <div style={{ position: 'relative' }}>
          <div style={{ width: '100%' }} id="iwhs47" className="swiper mySwiperproduct">
            <div className="swiper-wrapper">
              {
                products?.map((product) => (
                  <div key={product._id} id="i38vsn" className='swiper-slide'>
                    <img
                      className=""
                      id="itq462"
                      src={product.loftyUrl}
                      alt={product.name}
                    />

                    <p className="" id="idv4os">
                      <span>
                        {product.name}
                      </span>
                      <br className="" id="" />

                    </p>

                    {
                      store ? (
                        <p className="" id="iaqbuh">
                          <del>
                            <span>${`${product.normalprice.toFixed(2)}`}</span>
                            <div id="" className="">
                            </div>
                          </del>
                        </p>
                      ) : (
                        <p className="" id="iaqbuh">
                          <span>${`${product.normalprice.toFixed(2)}`}</span>
                          <div id="" className="">

                          </div>

                        </p>
                      )
                    }

                    {
                      store ? (
                        <>
                          {
                            store?.tiendaspottytag5ol9r ? (
                              <p className="" id="iaqbuh">
                                <h2>${`${product.precioespecialtiendapropiazerj1.toFixed(2)}`}</h2>
                                <div id="" className="">

                                </div>

                              </p>
                            ) : (
                              <p className="" id="iaqbuh">
                                <h2>${`${product.disccountprice.toFixed(2)}`}</h2>
                                <div id="" className="">

                                </div>

                              </p>
                            )
                          }
                        </>
                      ) : <></>
                    }

                    <div id="is4vch" className="">

                      <button
                        target=""
                        className=""
                        id="ijll8y"
                        onClick={() => {
                          const newArray = loftyShoppingCart.map((item) => item);
                          // agregando el nuevo producto gratis por tienda
                          if (store && !product.freestore) {
                            products.forEach((item) => {
                              if (item.freestore) {
                                newArray.push(item._id);
                              }
                            });
                          }
                          const exists = newArray.find((item2) => item2 === product._id);
                          if (!exists) {
                            newArray.push(product._id);
                          }
                          addLoftyShoppingCartApp(newArray);
                          alert('Added to shopping cart');
                        }}
                      >

                        <div id="ihmz9a" className="">
                          <span>Add to cart</span>
                        </div>

                      </button>

                    </div>

                  </div>
                ))
              }
            </div>


          </div>
        </div>

        <img
          className=""
          id="i4qyhg"
          src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hxr44.png"
          alt="undefined"
        />

        <img
          className=""
          id="ija8qb"
          src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hxr44.png"
          alt="undefined"
        />

      </div>

      <div id="ichds6" className="">

        <div id="i2ogx6" className="">

          <div id="i87ku3" className="">

            <div id="i64bfl" className="">

              <img
                className=""
                id="irztju"
                src="https://assetsprojects.s3.amazonaws.com/404p7niljspl4a5.png"
                alt="undefined"
              />

            </div>

            <div id="ilnm9v" className="">

              <p className="" id="ihov47">

                <span className="" id="i2v57k">

                  <b className="" id="">
                    <span>Solidarity Program</span>
                  </b>

                  <br className="" id="" />
                  <span>
                    We support the WHITE CITY FOUNDATION in the "home & adoption program for abandoned pets" in Latin American Countries.
                  </span>
                </span>

              </p>

            </div>

          </div>

        </div>

        <div id="io6g5h" className="">

          <div id="i82gav" className="">

            <div id="ilk7fh" className="">

              <img
                className=""
                id="i6rul9"
                src="https://assetsprojects.s3.amazonaws.com/404p7niljspq2a1.png"
                alt="undefined"
              />

            </div>

            <div id="ip52s8" className="">

              <p className="" id="iktv2h">

                <span className="" id="i7j3qk">

                  <b className="" id="">
                    <span>Quality Guarantee</span>
                  </b>

                  <br className="" id="" />
                  <span>All products are personalized providing unique quality.</span>
                </span>

              </p>

            </div>

          </div>

        </div>

        <div id="ivry5i" className="">

          <div id="igmds6" className="">

            <div id="i3ih67" className="">

              <img
                className=""
                id="ium1n1"
                src="https://assetsprojects.s3.amazonaws.com/404p7niljspsb51.png"
                alt="undefined"
              />

            </div>

            <div id="inmcsn" className="">

              <p className="" id="iyz2tm">

                <span className="" id="i2o5uq">

                  <b className="" id="">
                    <span>Help Pet assistance</span>
                  </b>

                  <br className="" id="" />
                  <span>identifies and makes it possible for your pet to return home safely and promptly.</span>
                </span>

              </p>

            </div>

          </div>

        </div>

      </div>

      <div id="i3wp2n" className="">

        <div id="iq6mrf" className="">

          <p className="" id="iav37s">
            <span>Mobile App Spotty Tag</span>
          </p>

          <p className="" id="i66i0t">
            <span>*To create and control your profile and keep the Electronic Diary and Health & Grooming Files TAG.</span>
            <br className="" id="" />
            <span>*It allows issuing alerts and receiving reports in case of loss of the pet</span>
            <br className="" id="" />
            <span>*Send to the Veterinarian or Grooming in PDF the medical or hygiene and beauty history</span>
            <br className="" id="" />
            <span>prior to your next appointment to know the latest diagnosis, prescription or treatment performed</span>
            <br className="" id="" />
            <span>to predetermine what comes next.</span>
          </p>

          <div id="imx1a4" className="">

            <a
              target=""
              className=""
              id="i84a68"
              href="https://apps.apple.com/hn/app/spotty-tag/id6443884976"
            >

              <img
                className=""
                id="ixu5m4"
                src="https://assetsprojects.s3.amazonaws.com/404p7nilj3if214.png"
                alt="undefined"
              />

            </a>

            <a
              target=""
              className=""
              id="i0r6tk"
              href="https://play.google.com/store/apps/details?id=com.fishermindtech.spottytag"
            >

              <img
                className=""
                id="i35c9u"
                src="https://assetsprojects.s3.amazonaws.com/404p7nilj3if7n3.png"
                alt="undefined"
              />

            </a>

          </div>

        </div>

        <img
          className=""
          id="ig4vw5"
          src="https://assetsprojects.s3.amazonaws.com/406racilqcn4u8r.png"
          alt="undefined"
        />

      </div>

      <div id="ie6sa" className="">

        <div id="iqobg" className="">

          <p className="" id="ibllc">
            <span>Contact us for more information!</span>
            <br className="" id="" />

          </p>

          <div id="ihuk4" className="">

            <div id="iu87j" className="">

              <img
                className=""
                id="i67f"
                src="https://assetsprojects.s3.amazonaws.com/eewk3il9okodib.png"
                alt="undefined"
              />

            </div>

            <div id="iwg4f" className="">

              <a
                target="_blank"
                className=""
                id="irgjju"
                href="https://www.instagram.com/spottytaghn/"
              >

                <img
                  className=""
                  id="iqcul1"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hafi3.png"
                  alt="undefined"
                />

                <p className="" id="iqq4mu">
                  <span>@spottytaghn</span>
                </p>

              </a>

              <a
                target="_blank"
                className=""
                id="i5vgyt"
                href="https://www.facebook.com/spottytaghn"
              >

                <img
                  className=""
                  id="iae27s"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3he6f6.png"
                  alt="undefined"
                />

                <p className="" id="ikdl4l">
                  <span>spottytaghn</span>
                </p>

              </a>

              <a
                target=""
                className=""
                id="ii2lsw"
                href="https://api.whatsapp.com/send?phone=50433027674"
              >

                <img
                  className=""
                  id="iu0rp5"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hfpjb.png"
                  alt="undefined"
                />

                <p className="" id="iqzql2">
                  <span>+504 3302-7674</span>
                </p>

              </a>

              <a
                target=""
                className=""
                id="i0mmmx"
                href="mailto:customerservice@spottytag.com"
              >

                <img
                  className=""
                  id="ih8ro2"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hg2uk.png"
                  alt="undefined"
                />

                <p className="" id="ivdrgi">
                  <span>customerservice@spottytag.com</span>
                </p>

              </a>

            </div>

          </div>

        </div>

      </div>

      <div id="ig891" className="">

        <p className="" id="i7l1k">
          <span>©2023 | spotty tag hn | privacy policies | terms of use</span>
          <br className="" id="" />

        </p>

      </div>

    </div>
  );
}

const mapStateToProps = (state) => ({ ...state.shoppingcart, ...state.store });

const mapDispatchToProps = (dispatch) => ({
  addLoftyShoppingCartApp: (loftyShoppingCart) => dispatch(addLoftyShoppingCart({ loftyShoppingCart }))
});

export default connect(mapStateToProps, mapDispatchToProps)(Ppagenewhome);
