
import {ADD_STORE, DELETE_STORE} from '../types/store.types';

export const addLoftyStore = ({store}) => {
  localStorage.setItem('store', JSON.stringify(store));
  const now = new Date();
  now.setDate(now.getDate() + 1);
  localStorage.setItem('validDateStore', now.toISOString());
  return {
    type: ADD_STORE,
    payload: {
      store,
      limitDate: now,
    },
  };
};

export const deleteLoftyStore = () => {
  localStorage.removeItem('validDateStore');
  localStorage.removeItem('store');
  return {
    type: DELETE_STORE
  }
}
