
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { getByNumberPlate } from '../../api/item.api';
import { getOnecountry } from '../../api/country.api';
import { getOneVeterinaryClinic } from '../../api/VeterinaryClinic.api';
import { getOnestate } from '../../api/state.api';
import { getAllimage } from '../../api/image.api';


import './itembyid.scss';


// Hamburger
function loftyInitHam() {
  try {
    // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}





const Pitembyid = ({ match, loftytoken, loftyuser, addLoftyUserApp }) => {

  const [doc, setDoc] = useState({});
  const { params } = match;
  const { id } = params;
  useEffect(() => {
    const handleData = async () => {
      try {
        let newDoc = {};
        const resItem = await getByNumberPlate({ numberPlate: id });
        resItem.data.forEach(item => {
          if (item.numberPlate === id) {
            newDoc = item;
          }
        });
        try {
          const resCountry = await getOnecountry({ token: '12121', _id: newDoc.idCountry })
          newDoc.dataCountry = resCountry.data;
        } catch (error) {
          console.log(error);
        }
        if (newDoc.idState) {
          try {
            const resState = await getOnestate({ token: 'as', _id: newDoc.idState });
            newDoc.dataSate = resState.data;
          } catch (error) {
            console.log(error);
          }
        }
        if (newDoc.idVeterinaryClinic) {
          try {
            const resVeterinary = await getOneVeterinaryClinic({ token: '12', _id: newDoc.idVeterinaryClinic });
            newDoc.dataVeterinary = resVeterinary.data;
          } catch (error) {
            console.log(error);
          }
        }
        const listImages = [];
        const resImages = await getAllimage({ token: '1' });
        resImages.data.forEach(item => {
          if (item.idItem === newDoc._id) {
            listImages.push(item);
          }
        });
        newDoc.listImages = listImages;
        setDoc(newDoc);
      } catch (error) {
        console.log(error);
      }
    }
    handleData()
  }, []);

  setTimeout(() => { loftyInitHam(); }, 2000);

  console.log(doc);

  return (
    <div id="id64d169f39255464525645f12" >

      <div id="ivee" className="">

        <a
          target=""
          className=""
          id="ivlkhb"
          href="/"
        >

          <img
            className=""
            id="i1i8k"
            src="https://assetsprojects.s3.amazonaws.com/eewk3il9okodib.png"
            alt="undefined"
          />

        </a>

        <a
          target=""
          className="shopping-container-mobile"
          id="i6hs3"
          href="/cartpage"
        >

          <img
            className=""
            id="ijy2n"
            src="https://assetsprojects.s3.amazonaws.com/406racilqpyblxz.png"
            alt="undefined"
          />

        </a>

        <div id="" className="contentwrapperanimated ">

          <div id="" className="container_animated ">

            <label id="" className="menu__btn ">

              <span className="span_before " id="">

              </span>

              <span className="span_middle " id="">

              </span>

              <span className="span_after " id="">

              </span>

            </label>

            <label id="" className="menu__close__btn ">

              <span className="span__close__left " id="">

              </span>

              <span className="span__close__right " id="">

              </span>

            </label>

            <ul id="" className="menu__box ">

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/"
                  type=""
                >
                  <span>Home</span>
                </a>

              </li>

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/products"
                  type=""
                >
                  <span>Shop</span>
                </a>

              </li>

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/aboutus"
                  type=""
                >
                  <span>About Us</span>
                </a>

              </li>

              <li className="linkshref " id="">

                <a
                  target=""
                  className="menu__item "
                  id=""
                  href="/cartpage"
                  type=""
                >
                  <span>Shopping Cart</span>
                </a>

              </li>

              <li className="linkshref " id="">

              </li>

            </ul>

          </div>

        </div>

        <div id="iq9l" className="">

          <a
            target=""
            className=""
            id="i5tql"
            href="/"
          >

            <div id="i1n85" className="">
              <span>Home</span>
            </div>

          </a>

          <a
            target=""
            className=""
            id="ifv1s"
            href="/products"
          >

            <div id="iqceg" className="">
              <span>Shop</span>
            </div>

          </a>

          <a
            target=""
            className=""
            id="ikkdy"
            href="/aboutus"
          >

            <div id="if57z" className="">
              <span>About Us</span>
            </div>

          </a>
          
          <a
            target=""
            className=""
            id="ixqzss"
            href="/blog"
          >

            <div id="i91zyl" className="">
              <span>Blog</span>
            </div>

          </a>

          <form
            id="islmj"
            className=""
            onSubmit={(e) => {
              e.preventDefault();
              const doc = {
                q: e.target.search.value,
              }
              const query = new URLSearchParams(doc);
              window.location.href = `/products?${query.toString()}`
            }}
          >

            <div id="i1otmv" className="">

              <input
                name="search"
                className=""
                id="iqnkxh"
              />

            </div>

            <button
              type="submit"
              name=""
              id="irtlk"
              className=""

            >

              <div id="ivb27" className="">
                <span>Search</span>
              </div>

            </button>

          </form>

          <div id="inqxd" className="">

            <a
              target=""
              className=""
              id="i6hs3"
              href="/cartpage"
            >

              <img
                className=""
                id="ijy2n"
                src="https://assetsprojects.s3.amazonaws.com/406racilqpyblxz.png"
                alt="undefined"
              />

            </a>

          </div>

        </div>

      </div>

      <div id="ipezzh" className="">

        <div id="ioo0tk" className="">

          <button
            type=""
            name=""
            id="ijfenn"
            className=""

          >

            <div id="iwowvw" className="">
              <span>Search</span>
            </div>

          </button>

        </div>

      </div>

      <div id="ihis35" className="">

        <div id="iw78x" className="">

          <div id="ipdx1n" className="">

            <div id="i4lrf" className="">

              <div id="i4aj1m" className="">

                <img
                  className=""
                  id="i42bof"
                  src={doc.listImages?.length > 0 ? doc.listImages[0].loftyUrl : 'https://assetsprojects.s3.amazonaws.com/eewk3il9okodib.png'}
                  alt="undefined"
                />

              </div>

              <div id="iy2iqp" className="">

                <p className="" id="iuzhxd">
                  <span>
                    {doc.description}
                  </span>
                  <div id="" className="">

                  </div>

                </p>

              </div>

            </div>

            <div id="ibn99" className="">

              <div id="ibjxr" className="gjs-row ">

                <div id="ihhbf" className="gjs-cell ">

                  <div id="ixo66" className="">

                    <p className="" id="idjo8">
                      <span>Information</span>
                      <div id="" className="">

                      </div>

                    </p>

                  </div>

                  <div id="is3l3" className="">

                    <div id="i5yzn" className="gjs-row ">

                      <div id="iitzq" className="gjs-cell ">

                        <p className="" id="i203fl">
                          <span>HEALTH & GROOMING TAG</span>
                        </p>

                      </div>

                      <div id="i2548" className="gjs-cell ">

                        <p className="" id="i75eye">
                          <span>{doc.numberPlate}</span>
                        </p>

                      </div>

                    </div>

                    <div id="iyno5t" className="gjs-row ">

                      <div id="iudw9m" className="gjs-cell ">

                        <p className="" id="ihzknx">
                          <span>RFID Microchip (if applies)</span>
                        </p>

                      </div>

                      <div id="i3rmpf" className="gjs-cell ">

                        <p className="" id="itjfvl">
                          <span>{doc.numberMicroship}</span>
                        </p>

                      </div>

                    </div>

                    <div id="iclu9a" className="gjs-row ">

                      <div id="iuxqic" className="gjs-cell ">

                        <p className="" id="ib1oa1">
                          <span>Birth date</span>
                        </p>

                      </div>

                      <div id="i3w1bh" className="gjs-cell ">

                        <p className="" id="i9v2gf">
                          <span>
                            {moment(doc?.age).format('DD/MM/YYYY')}
                          </span>
                        </p>

                      </div>

                    </div>

                    <div id="ioxaq8" className="gjs-row ">

                      <div id="ic2557" className="gjs-cell ">

                        <p className="" id="iedyfw">
                          <span>Sex</span>
                        </p>

                      </div>

                      <div id="iyl5xx" className="gjs-cell ">

                        <p className="" id="ibzbwf">
                          <span>{doc?.gender === 'female' ? 'Hembra' : 'Macho'}</span>
                        </p>

                      </div>

                    </div>

                    <div id="i3yycr" className="gjs-row ">

                      <div id="iq68dd" className="gjs-cell ">

                        <p className="" id="ix22lg">
                          <span>Fur color</span>
                        </p>

                      </div>

                      <div id="ivnhk5" className="gjs-cell ">

                        <p className="" id="iot82w">
                          <span>{doc?.color}</span>
                        </p>

                      </div>

                    </div>

                    <div id="i29ww4" className="gjs-row ">

                      <div id="ibrp8m" className="gjs-cell ">

                        <p className="" id="icjxok">
                          <span>Race</span>
                        </p>

                      </div>

                      <div id="i6684g" className="gjs-cell ">

                        <p className="" id="inz9rr">
                          <span>{doc?.colorcast}</span>
                        </p>

                      </div>

                    </div>

                    <div id="i8j17v" className="gjs-row ">

                      <div id="ilzv0x" className="gjs-cell ">

                        <p className="" id="i3awwv">
                          <span>Country</span>
                        </p>

                      </div>

                      <div id="i67m89" className="gjs-cell ">

                        <p className="" id="ijlwg8">
                          <span>{doc?.dataCountry?.name}</span>
                        </p>

                      </div>

                    </div>

                    <div id="idjid7" className="gjs-row ">

                      <div id="iy6zh6" className="gjs-cell ">

                        <p className="" id="inryud">
                          <span>State / Department / Province</span>
                        </p>

                      </div>

                      <div id="ibxmw5" className="gjs-cell ">

                        <p className="" id="ig9kgi">
                          <span>{doc?.dataSate?.name}</span>
                        </p>

                      </div>

                    </div>

                    <div id="i1xng7" className="gjs-row ">

                      <div id="iaow34" className="gjs-cell ">

                        <p className="" id="imgpk7">
                          <span>City</span>
                        </p>

                      </div>

                      <div id="iylvv6" className="gjs-cell ">

                        <p className="" id="i22nok">
                          <span>{doc?.city}</span>
                        </p>

                      </div>

                    </div>

                    <div id="ibbkrj" className="gjs-row ">

                      <div id="imbh5n" className="gjs-cell ">

                        <p className="" id="itl5zs">
                          <span>Veterinary Clinic</span>
                        </p>

                      </div>

                      <div id="iqhyiy" className="gjs-cell ">

                        <p className="" id="iqppun">
                          <span>{doc?.dataVeterinary?.name}</span>
                        </p>

                      </div>

                    </div>
                    <div id="ibbkrj" className="gjs-row ">

                      <div id="imbh5n" className="gjs-cell ">

                        <p className="" id="itl5zs">
                          <span>Phone 1</span>
                        </p>

                      </div>

                      <div id="iqhyiy" className="gjs-cell ">

                        <p className="" id="iqppun">
                          <span>{doc?.phone1}</span>
                        </p>

                      </div>

                    </div>
                    <div id="ibbkrj" className="gjs-row ">

                      <div id="imbh5n" className="gjs-cell ">

                        <p className="" id="itl5zs">
                          <span>Phone 2</span>
                        </p>

                      </div>

                      <div id="iqhyiy" className="gjs-cell ">

                        <p className="" id="iqppun">
                          <span>{doc?.phone2}</span>
                        </p>

                      </div>

                    </div>

                    <div id="ijoogh" className="gjs-row ">

                      <div id="iamufr" className="gjs-cell ">

                        <p className="" id="iclha5">
                          <span>Extra Information</span>
                        </p>

                      </div>

                      <div id="ijcpw7" className="gjs-cell ">

                        <p className="" id="ir8v8d">
                          <span>
                            {doc?.extraInformation}
                          </span>
                        </p>

                      </div>

                    </div>

                  </div>

                </div>

                <div id="iflbg" className="gjs-cell ">

                  <div id="il9by" className="">

                    <p className="" id="iwon5c">
                      <span>History</span>
                      <div id="" className="">

                      </div>

                    </p>

                  </div>

                  <div id="icbjv" className="">

                    <div id="i10pai" className="gjs-row ">

                      <div id="igjnj2" className="gjs-cell ">

                        <p className="" id="i6p35v">
                          <span>Prescriptions</span>
                        </p>

                      </div>

                      <div id="izgukj" className="gjs-cell ">

                        <p className="" id="i70idg">
                          <span>
                            {doc?.medicines}
                          </span>
                        </p>

                      </div>

                    </div>

                    <div id="i6tdgw" className="gjs-row ">

                      <div id="i1g2p5" className="gjs-cell ">

                        <p className="" id="ityexd">
                          <span>Allergies/Illneses</span>
                        </p>

                      </div>

                      <div id="ivyxmg" className="gjs-cell ">

                        <p className="" id="i0htu3">
                          <span> {doc?.allergies} </span>
                        </p>

                      </div>

                    </div>

                    <div id="i7kr61" className="gjs-row ">

                      <div id="izm4ah" className="gjs-cell ">

                        <p className="" id="i4qyex">
                          <span>Vaccines</span>
                        </p>

                      </div>

                      <div id="ica702" className="gjs-cell ">

                        <p className="" id="isp30g">
                          <span>
                            {doc.vaccines}
                          </span>
                        </p>

                      </div>

                    </div>

                    <div id="i0zfok" className="gjs-row ">

                      <div id="ih76kg" className="gjs-cell ">

                        <p className="" id="ip7x2h">
                          <span>Surgeries</span>
                        </p>

                      </div>

                      <div id="i5m5cj" className="gjs-cell ">

                        <p className="" id="iqts5h">
                          <span>
                            {doc.surgeries}
                          </span>
                        </p>

                      </div>

                    </div>

                    <div id="id03la" className="gjs-row ">

                      <div id="ijoghn" className="gjs-cell ">

                        <p className="" id="i86gxb">
                          <span>Number of Births</span>
                        </p>

                      </div>

                      <div id="i0lg4i" className="gjs-cell ">

                        <p className="" id="isvmzf">
                          <span>
                            {doc.childbirth}
                          </span>
                        </p>

                      </div>

                    </div>

                    <div id="ik3z7f" className="gjs-row ">

                      <div id="i9y9de" className="gjs-cell ">

                        <p className="" id="iyfhp3">
                          <span>Pedigree</span>
                        </p>

                      </div>

                      <div id="i5oyrg" className="gjs-cell ">

                        <p className="" id="i9akz2">
                          <span>
                            {doc.pedigree}
                          </span>
                        </p>

                      </div>

                    </div>

                    <div id="ijtvb7" className="gjs-row ">

                      <div id="izl1xv" className="gjs-cell ">

                        <p className="" id="i4tbkh">
                          <span>Dewormers</span>
                        </p>

                      </div>

                      <div id="i6ce9i" className="gjs-cell ">

                        <p className="" id="igw5p4">
                          <span>
                            {doc.dewormers}
                          </span>
                        </p>

                      </div>

                    </div>

                    <div id="i0uudf" className="gjs-row ">

                      <div id="iyhkbz" className="gjs-cell ">

                        <p className="" id="icokhl">
                          <span>Number of Bone Breaks</span>
                        </p>

                      </div>

                      <div id="i16z72" className="gjs-cell ">

                        <p className="" id="iq46hg">
                          <span>
                            {doc.fractures}
                          </span>
                        </p>

                      </div>

                    </div>


                  </div>

                </div>

              </div>

              {
                doc.lost ? (
                  <div style={{textAlign: 'center', marginTop: 35, marginBottom: 35}}>
                    <a style={{paddingLeft: 25, paddingRight: 25, paddingTop: 10, paddingBottom: 10, textAlign: 'center', width: 180}} href={`/report/item/${doc._id}`} id='irtlk'>
                      <span id='ivb27'>
                        Report
                      </span>
                    </a>
                  </div>
                ) : <></>
              }

            </div>

          </div>

        </div>

      </div>

      <div id="ie6sa" className="">

        <div id="iqobg" className="">

          <p className="" id="ibllc">
            <span>Contact us for more information!</span>
            <div id="" className="">

            </div>

          </p>

          <div id="ihuk4" className="">

            <div id="iu87j" className="">

              <img
                className=""
                id="i67f"
                src="https://assetsprojects.s3.amazonaws.com/eewk3il9okodib.png"
                alt="undefined"
              />

            </div>

            <div id="iwg4f" className="">

              <a
                target="_blank"
                className=""
                id="irgjju"
                href="https://www.instagram.com/spottytaghn/"
              >

                <img
                  className=""
                  id="iqcul1"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hafi3.png"
                  alt="undefined"
                />

                <p className="" id="iqq4mu">
                  <span>@spottytaghn</span>
                </p>

              </a>

              <a
                target="_blank"
                className=""
                id="i5vgyt"
                href="https://www.facebook.com/spottytaghn"
              >

                <img
                  className=""
                  id="iae27s"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3he6f6.png"
                  alt="undefined"
                />

                <p className="" id="ikdl4l">
                  <span>spottytaghn</span>
                </p>

              </a>

              <a
                target=""
                className=""
                id="ii2lsw"
                href="https://api.whatsapp.com/send?phone=50433027674"
              >

                <img
                  className=""
                  id="iu0rp5"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hfpjb.png"
                  alt="undefined"
                />

                <p className="" id="iqzql2">
                  <span>+504 3302-7674</span>
                </p>

              </a>

              <a
                target=""
                className=""
                id="i0mmmx"
                href="mailto:customerservice@spottytag.com"
              >

                <img
                  className=""
                  id="ih8ro2"
                  src="https://assetsprojects.s3.amazonaws.com/404p7nilj3hg2uk.png"
                  alt="undefined"
                />

                <p className="" id="ivdrgi">
                  <span>customerservice@spottytag.com</span>
                </p>

              </a>

            </div>

          </div>

        </div>

      </div>

      <div id="ig891" className="">

        <p className="" id="i7l1k">
          <span>©2023 | spotty tag hn | privacy policies | terms of use</span>
          <div id="" className="">

          </div>

        </p>

      </div>

    </div>
  );
}

const mapStateToProps = (state) => state.auth;



export default connect(mapStateToProps)(Pitembyid);
