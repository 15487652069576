
import axios from '../config/axios';

export const url = '/api/logstripeordenlofty';

export const createLogStripelordenLofty = ({
  email, nameclient, country, city, address, telephone, subtotal, discount, total,
  costdelivery, idstore, shoppingCart, idstate, idcountry, idStripeLofty, products,
  zipCode,
}) => new Promise((resolve, reject) => {
  axios.post(`${url}/create`, {
    email, nameclient, country, city, address, telephone, subtotal, discount, total,
    costdelivery, idstore, shoppingCart, idstate, idcountry, idStripeLofty, products, zipCode
  }).then(res => {
    if (res.data.status === 'success') {
      resolve(res.data);
    } else {
      reject(res.data);
    }
  }).catch((error) => {
    reject(error.response?.data);
  })
});
