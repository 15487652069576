
import {ADD_STORE, DELETE_STORE} from '../types/store.types';

const initialState = {
  store: localStorage.getItem('store') ? JSON.parse(localStorage.getItem('store')) : undefined,
  limitDate: localStorage.getItem('validDateStore') ? new Date(localStorage.getItem('validDateStore')) : undefined,
};

const reducerAuth = (state = initialState, {type, payload}) => {
  switch (type) {
    case ADD_STORE:
      return {
        ...state,
        store: payload?.store,
        limitDate: payload?.limitDate
      };
    case DELETE_STORE:
      return {
        ...state,
        store: undefined,
        limitDate: undefined,
      }
    default:
      return state;
  }
};

export default reducerAuth;
